import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2><a id="unearthed-arcana"></a>Unearthed Arcana</h2>
    <h3><a id="sanity"></a>
SANITY
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/UnA_Gallery/79180.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a>
This variant system allows you to introduce an element of dark horror into
your d20 game. In campaigns using these rules, characters gain a new
attribute called Sanity. This statistic functions like an ability score in
some ways, but it has its own unique mechanics that represent the
character�s descent from a stable and healthy mental state into confusion,
dementia, and mental instability. As a character encounters monsters,
witnesses horrible acts, masters forbidden knowledge, or casts spells, his
Sanity score, and his corresponding ability to function as a normal member
of his race, deteriorates. This gradual descent is balanced in part by the
powers that characters gain each time they overcome a horrific foe or grow
in skill and expertise, but even as those characters grow in power, they
know or fear that an even greater peril lies ahead � the threat of becoming
permanently insane.
    <h5><a id="behind-the-curtain-sanity"></a>
BEHIND THE CURTAIN: SANITY
    </h5>
    <div style={{
      "marginLeft": "40px"
    }}>
Because it affects the way that characters interact with the creatures and
objects that they encounter on their adventures in many different and
profound ways, this variant, perhaps more than any other in Unearthed
Arcana, can alter the entire feel of a campaign. If you adopt this variant
in your campaign, the largest change will most likely be one of tone (this
applies to an even greater extent if you adopt the entire Cthulhu Mythos
that the variant is based on).
      <p>
As in the Call of Cthulhu Roleplaying Game, characters feel more
vulnerable, for no matter how powerful they become, the dark gods are
always greater. Religion is not only a source of comfort or succor, but
also a dangerous enemy. (In those games that use the Cthulhu Mythos,
religion seldom provides any succor at all.) Characters are suspicious,
even paranoid, for a seemingly innocent commoner could secretly serve a
cult. And yet, with such dark challenges come the opportunities for
greater heroism.
      </p></div>
    <h5><a id="what-is-sanity"></a>
WHAT IS SANITY?
    </h5>
Sanity is the natural mental state of ordinary life. Normal mental balance
is endangered when characters confront horrors, entities, or activities
that are shocking, unnatural, and bewildering. Such encounters cause a
character to lose points from his Sanity score, which in turn risks
temporary, indefinite, or permanent insanity. Mental stability and lost
Sanity points can be restored, up to a point, but psychological scars may
remain.
    <p>
Insanity occurs if too many Sanity points are lost in too short a time.
Insanity does not necessarily occur if Sanity points are low, but a lower
Sanity score makes some forms of insanity more likely to occur after a
character experiences an emotional shock. The character�s Sanity may be
regained after a few minutes, recovered after a few months, or lost
forever.
    </p>
    <p>
A character may regain Sanity points, and even increase her Sanity point
maximum. However, increasing a character�s ranks in the <a style={{
        "color": "#579eb6"
      }} href="#knowledge-forbidden-lore">Knowledge
(forbidden lore)</a> skill always lowers her maximum Sanity by an equal
amount.
    </p>
    <h5><a id="forbidden-knowledge"></a>
FORBIDDEN KNOWLEDGE
    </h5>
    <div style={{
      "marginLeft": "40px"
    }}>
The Sanity rules assume that some knowledge is so alien to human
understanding that simply learning of its existence can shatter the
psyche. While magic and nonhuman races form an everyday part of a d20
character�s life, even a seasoned adventurer cannot conquer or understand
some things. Knowledge of these secrets and creatures is represented by a
new skill that goes hand in hand with a character�s Sanity score:
      <a style={{
        "color": "#579eb6"
      }} href="#knowledge-forbidden-lore">Knowledge (forbidden lore)</a>.
      <p>
This type of knowledge permanently erodes a character�s ability to
maintain a stable and sane outlook, and a character�s current Sanity can
never be higher than 99 minus the modifier the character has in the
        <a style={{
          "color": "#579eb6"
        }} href="#knowledge-forbidden-lore">Knowledge (forbidden lore)</a> skill. This number (99 minus Knowledge
[forbidden lore] ranks) is the character�s maximum Sanity.
      </p>
      <h6><a id="knowledge-forbidden-lore"></a>
Knowledge (Forbidden Lore) (None)
      </h6>
You know That Which Should Not Be Known. You have had horrible
supernatural experiences and read forbidden tomes, learning truly dark
secrets that have challenged everything you thought you knew. Since these
revelations defy logic or commonly accepted fact, it does not matter how
intelligent or wise you are when using this skill � only how much exposure
to these dark secrets themselves you have experienced.
      <p><b>Check:</b> Answering a question about the horrible deities and secrets that lurk at
the edges of reality has a DC of 10 (for really easy questions), 15 (for
elementary questions), or 20 to 30 (for difficult or really tough
questions). Unlike in other fields of study, there are almost no really
easy questions associated with this dark knowledge.
      </p>
      <p>
You can use this skill to identify monsters and their special powers or
vulnerabilities. In general, the DC of such a check equals 10 + the
monster�s HD. A successful check allows you to remember a bit of useful
information about that monster. For every 5 points by which your check
result exceeds the DC, the GM can give another piece of useful
information.
      </p>
      <p>
The GM can decide which monsters are subject to the Knowledge (forbidden
lore) skill and which monsters are subject to one of the standard
        <a style={{
          "color": "#579eb6"
        }} href="skillsAll.html#knowledge">Knowledge</a> skills. For example, the GM may rule that Knowledge (the planes)
is still the relevant skill for learning or knowing about outsiders,
rather than allowing them to be identified by Knowledge (forbidden lore).
However, in most campaigns that use the Sanity variant, aberrations and
oozes should be able to be identified by Knowledge (forbidden lore) rather
than by Knowledge (arcana) and Knowledge (dungeoneering) respectively.
      </p>
      <p><b>Action:</b> Usually none. In most cases, making a Knowledge check doesn�t take an
action�you simply know the answer or you don�t.
      </p>
      <p><b>Try Again:</b> No. The check represents what you know, and thinking about a topic a
second time doesn�t let you know something that you never learned in the
first place.
      </p>
      <p><b>Special:</b>You cannot gain ranks in this skill by spending skill points. You can only
gain ranks by reading forbidden tomes or having experiences with horrible
creatures. Each rank you gain in this skill permanently reduces your
maximum Sanity by 1 point: The more you know about the horrible truths
underlying reality, the less capable you are of leading a normal life.
      </p>
      <p>
A character�s first episode of insanity (that is, an occurrence of
temporary or indefinite insanity) bestows 2 ranks in the Knowledge
(forbidden lore) skill, thereby lowering his maximum Sanity by 2 points.
Each time a character fails a Sanity check and endures another episode of
insanity, he gains an additional rank in Knowledge (forbidden lore).
      </p>
      <p>
For example, Torin has 1 rank of Knowledge (forbidden lore) after
reading a strange manuscript. She then steps outside, sees a chaos beast,
and goes indefinitely insane, her raving mind failing to understand the
strange creature she has encountered. Since she has never gone insane
before, her player adds 2 ranks of Knowledge (forbidden lore) to Torin�s
character sheet. Now Torin�s Maximum Sanity is 96 (99 minus 3 ranks of
Knowledge [forbidden lore]).
      </p>
      <p>
You cannot take the Knowledge (forbidden lore) skill during character
creation. However, the skill has no maximum rank; your level does not
limit the number of ranks in Knowledge (forbidden lore) that you can
acquire.
      </p></div>
    <h5><a id="sanity-points"></a>
SANITY POINTS
    </h5>
Sanity points measure the stability of a character�s mind. This attribute
provides a way to define the sanity inherent in a character, the most
stability a character can ever have, and the current level of sane
rationality that a character preserves, even after numerous shocks and
horrid revelations.
    <p>
Sanity is measured in three ways: starting Sanity, current Sanity, and
maximum Sanity. Starting and current Sanity cannot exceed maximum Sanity.
    </p>
    <h6><a id="starting-sanity"></a>
Starting Sanity
    </h6>
A character�s starting Sanity equals his Wisdom score multiplied by 5.
This score represents a starting character�s current Sanity, as well as
the upper limit of Sanity that can be restored by the <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#heal">Heal</a> skill (see <a style={{
      "color": "#579eb6"
    }} href="#the-heal-skill-and-mental-treatment">The
Heal Skill and Mental Treatment</a>, later in this section). After creation, a
character�s current Sanity often fluctuates considerably and might never
again match starting Sanity. A change in a character�s Wisdom score
changes his starting Sanity in terms of what treatment with the Heal skill
can restore. Current Sanity, however, does not change if Wisdom rises or
falls.
    <h6><a id="current-sanity"></a>
Current Sanity
    </h6>
A character�s current Sanity score fluctuates almost as often as (and
sometimes much more often than) his hit points.
    <p><b><a id="making-a-sanity-check"></a>Making a Sanity Check:</b>
When a character encounters a gruesome, unnatural, or supernatural
situation, the GM may require the player to make a Sanity check using
percentile dice (d%). The check succeeds if the result is equal to or less
than the character�s current Sanity.
    </p>
    <p>
On a successful check, the character either loses no Sanity points or
loses only a minimal amount. Potential Sanity loss is usually shown as two
numbers or die rolls separated by a slash, such as 0/1d4. The number
before the slash indicates the number of Sanity points lost if the Sanity
check succeeds (in this case, none); the number after the slash indicates
the number of Sanity points lost if the Sanity check fails (in this case,
between 1 and 4 points).
    </p>
    <p>
A character�s current Sanity is also at risk when the character reads
certain books, learns certain types of spells, and attempts to cast them.
These Sanity losses are usually automatic (no Sanity check is allowed);
the character who chooses to undertake the activity forfeits the indicated
number of Sanity points.
    </p>
    <p>
In most cases, a new Sanity-shaking confrontation requires a new Sanity
check. However, the GM always gets to decide when characters make Sanity
checks. Confronting several horribly mangled corpses at one time or in
rapid succession may call for just one Sanity check, while the same
corpses encountered singly over the course of several game hours may
require separate checks.
    </p>
    <p><b><a id="going-insane"></a>Going Insane:</b>
Losing more than a few Sanity points may cause a character to go insane,
as described below. If a character�s Sanity score drops to 0 or lower, she
begins the quick slide into permanent insanity. Each round, the character
loses another point of Sanity. Once a character�s Sanity score reaches
-10, she is hopelessly, incurably insane. The <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#heal">Heal</a> skill can be used to
stabilize a character on the threshold of permanent insanity; see <a style={{
        "color": "#579eb6"
      }} href="#the-heal-skill-and-mental-treatment">The Heal
Skill and Mental Treatment</a>, below, for details.
    </p>
    <p>
A GM�s description of a Sanity-shaking situation should always justify the
threat to a character�s well-being. Thus, a horde of frothing rats is
horrifying, while a single ordinary rat usually is not (unless the
character has an appropriate phobia, of course).
    </p>
    <h6><a id="maximum-sanity"></a>
Maximum Sanity
    </h6>
Ranks in the <a style={{
      "color": "#579eb6"
    }} href="#knowledge-forbidden-lore">Knowledge (forbidden lore)</a> skill simulate a character�s
comprehension of aspects of the dark creatures at the edges of reality.
Once gained, this horrible knowledge is never forgotten, and the character
consequently surrenders mental equilibrium. A character�s Sanity weakens
as his comprehension of these hidden truths increases. Such is the way of
the universe.
    <p>
A character�s current Sanity can never be higher than 99 minus the
character�s ranks in the <a style={{
        "color": "#579eb6"
      }} href="#knowledge-forbidden-lore">Knowledge (forbidden lore)</a> skill. This number (99
minus Knowledge [forbidden lore] ranks) is the character�s maximum Sanity.
    </p>
    <h5><a id="loss-of-sanity"></a>
LOSS OF SANITY
    </h5>
Characters ordinarily lose Sanity in a few types of circumstances: when
encountering something unimaginable, when suffering a severe shock, after
casting a spell or when learning a new spell, when being affected by a
certain type of magic or a particular spell, or when reading a forbidden
tome.
    <h6><a id="encountering-the-unimaginable"></a>
Encountering the Unimaginable
    </h6>
When people perceive creatures and entities of unspeakable horror, this
experience costs them some portion of their minds, since such creatures
are intrinsically discomforting and repellent. We never lose awareness of
their slimy, fetid, alien nature. This instinctive reaction is part and
parcel of humans, elves, dwarves, and other humanoid races. In this
category, we can include supernatural events or agents not always
recognized as specifically devoted to these dark gods, such as hauntings,
zombies, vampires, curses, and so on.
    <p>
      <a style={{
        "color": "#579eb6"
      }} href="#table-sanity-loss-from-creatures">Table: Sanity Loss from Creatures</a> provides some default Sanity loss values
for encountering creatures, based on their type and size. These are only
default values � the GM can and should adjust individual monsters he deems
more or less horrible than others of their size. An <a style={{
        "color": "#579eb6"
      }} href="monstersOtoR.html#planetouched">aasimar</a>, for instance,
hardly presents a Sanity-shaking sight, and should probably be treated as
a humanoid rather than an outsider. On the other hand, a <a style={{
        "color": "#579eb6"
      }} href="monstersTtoZ.html#vargouille">vargouille</a> � a
Small outsider appearing much like a flying, bat-winged head � might provoke
a much more visceral reaction than other Small outsiders.
    </p>
    <p>
In addition, certain types of monstrous behavior might force additional
Sanity checks, much like those described under Severe Shocks, below. For
instance, an <a style={{
        "color": "#579eb6"
      }} href="monstersA.html#aboleth">aboleth</a> is an
unnerving sight, but watching one transform your best friend with it's
slime should certainly force another check, with losses appropriate to the
situation.
    </p>
    <p>
In most d20 games, no character should need to make a Sanity check when
encountering an elf, dwarf, or other standard humanoid race, or for
encountering domesticated or otherwise commonplace animals. In some cases,
even humanoid races such as orcs and goblins might be so common as to not
cause Sanity loss either.
    </p>
    <p><b><a id="specific-monsters-and-sanity"></a>Specific Monsters and Sanity:</b>
Some monsters have additional or variant special abilities when using the
Sanity variant.
    </p>
    <p><i><a style={{
          "color": "#579eb6"
        }} href="monstersA.html#allip">Allip</a>:</i>
The allip�s madness ability causes the loss of 2d6 Sanity points rather
than the normal effect.
    </p>
    <p><i><a style={{
          "color": "#579eb6"
        }} href="monstersDtoDe.html#derro">Derro</a>:</i>
The derro�s madness ability protects these creatures from any further
Sanity loss. Sane derro (especially derro player characters) track Sanity
points normally.
    </p>
    <p><b><a id="monstrous-characters-and-sanity"></a>Monstrous Characters and Sanity:</b>
In most cases, the GM does not need to keep track of a monster�s Sanity
score, but sometimes, especially when in the hands of a player, monsters
need Sanity scores just as other characters do.
    </p>
    <p>
Although most campaigns that use the Sanity variant limit players to
creating characters from the <a style={{
        "color": "#579eb6"
      }} href="races.html">standard player
character races</a>, it�s still easy to envision a world where one or two
monstrous races are so common or so integrated into the culture of other
races that the GM wants to present them as viable player character
options. In these cases, the monsters available as player character races
should never provoke a Sanity loss from other characters or NPCs, and
these creatures should have a Sanity score and track their Sanity losses
just like characters made with the standard PC races. A monster should
never lose Sanity for seeing others of its race (<a style={{
        "color": "#579eb6"
      }} href="monstersS.html#spectre">spectres</a> don�t lose
Sanity when encountering other spectres, and so on). In all cases, what
causes Sanity loss for a specific creature is in the hands of the GM.
    </p>
    <b><a id="table-sanity-loss-from-creatures"></a>Table: Sanity Loss from Creatures</b>
    <table cellSpacing="0" style={{
      "width": "100%"
    }}>
      <thead>
        <tr>
          <th rowSpan="2" align="left">
Monster Type
          </th>
          <th colSpan="7">
            <p>{`Monster Size`}</p>
          </th>
        </tr>
        <tr>
          <th>
Up to Tiny
          </th>
          <th>
Small
          </th>
          <th>
Medium
          </th>
          <th>
Large
          </th>
          <th>
Huge
          </th>
          <th>
            <p>{`Gargantuan`}</p>
          </th>
          <th>
Colossal
          </th>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <td colSpan="8">
            <ol>
              <li>Only animals or humanoids of truly bizarre or ferocious
appearance force such a check.
              </li>
            </ol>
          </td>
        </tr>
      </tfoot>
      <tbody>
        <tr className="odd-row">
          <td>
Aberration, dragon, ooze, outsider, undead
          </td>
          <td align="center">
1/1d4
          </td>
          <td align="center">
1/1d4
          </td>
          <td align="center">
            <p>{`1/1d6`}</p>
          </td>
          <td align="center">
1/1d10
          </td>
          <td align="center">
1d4/1d10
          </td>
          <td align="center">
1d6/1d10
          </td>
          <td align="center">
1d6/2d10
          </td>
        </tr>
        <tr>
          <td>
Elemental, fey, plant, vermin
          </td>
          <td align="center">
0/1d4
          </td>
          <td align="center">
1/1d4
          </td>
          <td align="center">
1/1d6
          </td>
          <td align="center">
1/1d8
          </td>
          <td align="center">
            <p>{`1/1d10`}</p>
          </td>
          <td align="center">
1d4/1d10
          </td>
          <td align="center">
1d4/2d6
          </td>
        </tr>
        <tr className="odd-row">
          <td>
Construct, giant, magical beast, monstrous humanoid
          </td>
          <td align="center">
0/1
          </td>
          <td align="center">
0/1d4
          </td>
          <td align="center">
            <p>{`0/1d6`}</p>
          </td>
          <td align="center">
1/1d6
          </td>
          <td align="center">
2/2d6
          </td>
          <td align="center">
2/2d6
          </td>
          <td align="center">
3/3d6
          </td>
        </tr>
        <tr>
          <td>
Animal, humanoid
          </td>
          <td align="center">
0/0<sup><sub>1</sub></sup>
          </td>
          <td align="center">
0/1<sup><sub>1</sub></sup>
          </td>
          <td align="center">
0/1<sup><sub>1</sub></sup>
          </td>
          <td align="center">
0/1d4<sup><sub>1</sub></sup>
          </td>
          <td align="center">
0/1d4
          </td>
          <td align="center">
0/1d4
          </td>
          <td align="center">
0/1d6
          </td>
        </tr>
      </tbody>
    </table>
    <h6><a id="severe-shocks"></a>
Severe Shocks
    </h6>
A shocking sight of a more mundane nature can also cost Sanity points.
Severe shocks include witnessing an untimely or violent death,
experiencing personal mutilation, losing social position, being the victim
of treachery, or whatever else the GM decides is sufficiently
extreme. The following list gives some examples of severe shocks, and the
Sanity loss each one provokes.
    <a id="table-severe-shocks"></a>
    <table cellSpacing="0" style={{
      "width": "50%"
    }}>
      <thead>
        <tr>
          <th align="left">
Sanity Lost<sup><sub>1</sub></sup>
          </th>
          <th align="left">
Shocking Situation
          </th>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <td colSpan="2">
            <ol>
              <li>Loss on a successful check/loss on a failed check.
              </li>
            </ol>
          </td>
        </tr>
      </tfoot>
      <tbody>
        <tr className="odd-row">
          <td>
            <p>{`0/1d2`}</p>
          </td>
          <td>
Surprised to find mangled animal carcass
          </td>
        </tr>
        <tr>
          <td>
0/1d3
          </td>
          <td>
Surprised to find human corpse
          </td>
        </tr>
        <tr className="odd-row">
          <td>
0/1d3
          </td>
          <td>
Surprised to find human body part
          </td>
        </tr>
        <tr>
          <td>
0/1d4
          </td>
          <td>
Finding a stream flowing with blood
          </td>
        </tr>
        <tr className="odd-row">
          <td>
1/1d4+1
          </td>
          <td>
Finding a mangled human corpse
          </td>
        </tr>
        <tr>
          <td>
0/1d6
          </td>
          <td>
Awakening trapped in a coffin
          </td>
        </tr>
        <tr className="odd-row">
          <td>
0/1d6
          </td>
          <td>
Witnessing a friend�s violent death
          </td>
        </tr>
        <tr>
          <td>
1/1d6
          </td>
          <td>
Seeing a ghoul
          </td>
        </tr>
        <tr className="odd-row">
          <td>
1/1d6+1
          </td>
          <td>
Meeting someone you know to be dead
          </td>
        </tr>
        <tr>
          <td>
0/1d10
          </td>
          <td>
Undergoing severe torture
          </td>
        </tr>
        <tr className="odd-row">
          <td>
1/d10
          </td>
          <td>
Seeing a corpse rise from its grave
          </td>
        </tr>
        <tr>
          <td>
2/2d10+1
          </td>
          <td>
Seeing a gigantic severed head fall from the sky
          </td>
        </tr>
        <tr className="odd-row">
          <td>
1d10/d%
          </td>
          <td>
Seeing an evil deity
          </td>
        </tr>
      </tbody>
    </table>
    <h6><a id="casting-spells"></a>
Casting Spells
    </h6>
Magic relies on the physics of the true universe. By casting spells,
characters visualize the unimaginable, warping their minds to follow alien
ways of thought. These visualizations wound the mind. Although
spellcasters expose themselves to such traumas voluntarily, they are
shocks all the same.
    <p>
In this variant, casting a spell drains a certain amount of Sanity. This
rule represents the fact that spellcasting forces the mind into strange
patterns and thought processes with which it is poorly equipped to deal.
The GM can choose from the three options presented in the table below,
deducting a low, moderate, or extreme number of Sanity points from a
character who casts a spell. In a campaign featuring low Sanity loss, a
spellcaster is rarely penalized for casting a spell, especially if the GM
also chooses to give characters <a style={{
        "color": "#579eb6"
      }} href="#sanity-resistance">Sanity resistance</a> in
such cases. In a campaign featuring moderate Sanity loss, spellcasters
face a slightly higher risk of insanity than members of other classes,
even if they have Sanity resistance. When using this option, players
should, for the most part, choose spellcasting levels only as multiclass
options. In a campaign featuring extreme Sanity loss, spellcasters have a
difficult time participating in adventures regularly because they find it
hard to use their classes� primary abilities without soon going insane.
    </p>
    <a id="table-sanity-loss-from-spellcasting"></a>
    <table cellSpacing="0" style={{
      "width": "40%"
    }}>
      <tr>
        <th>
Spell
          <p>{`Level`}</p>
        </th>
        <th>
Low
          <p>{`Sanity Loss`}</p>
        </th>
        <th>
Moderate
          <p>{`Sanity Loss`}</p>
        </th>
        <th>
Extreme
          <p>{`Sanity Loss`}</p>
        </th>
      </tr>
      <tr className="odd-row">
        <td align="center">
1st
        </td>
        <td align="center">
1
        </td>
        <td align="center">
2
        </td>
        <td align="center">
1d6
        </td>
      </tr>
      <tr>
        <td align="center">
2nd
        </td>
        <td align="center">
2
        </td>
        <td align="center">
4
        </td>
        <td align="center">
2d6
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
3rd
        </td>
        <td align="center">
3
        </td>
        <td align="center">
6
        </td>
        <td align="center">
3d6
        </td>
      </tr>
      <tr>
        <td align="center">
4th
        </td>
        <td align="center">
4
        </td>
        <td align="center">
8
        </td>
        <td align="center">
4d6
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
5th
        </td>
        <td align="center">
5
        </td>
        <td align="center">
10
        </td>
        <td align="center">
5d6
        </td>
      </tr>
      <tr>
        <td align="center">
6th
        </td>
        <td align="center">
6
        </td>
        <td align="center">
12
        </td>
        <td align="center">
6d6
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
7th
        </td>
        <td align="center">
7
        </td>
        <td align="center">
14
        </td>
        <td align="center">
7d6
        </td>
      </tr>
      <tr>
        <td align="center">
8th
        </td>
        <td align="center">
8
        </td>
        <td align="center">
16
        </td>
        <td align="center">
8d6
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
9th
        </td>
        <td align="center">
9
        </td>
        <td align="center">
18
        </td>
        <td align="center">
9d6
        </td>
      </tr>
    </table>
    <p>
In addition to the guidelines presented for each campaign option above,
the GM is free to impose additional modifiers on the Sanity loss caused by
spells of a certain type, a certain school, or even individual spells.
Here are some example conditions that a GM could choose to incorporate
into a game.
    </p>
    <ul>
      <li>Abjurations cost 1 less Sanity point to cast.
      </li>
      <li>Divine spells cost 5 additional Sanity points to cast.
      </li>
      <li>Druids suffer only half the Sanity loss from spellcasting (round
fractions up).
      </li>
      <li>Healing spells drain no Sanity when cast.
      </li>
      <li>The invisibility spell, and any variation thereof, costs 1 additional
Sanity Point to cast.
      </li>
      <li>Necromancy spells cost 2 additional Sanity points to cast.
      </li>
      <li>Spellcasters of some classes follow the guidelines of one campaign
option, while spellcasters of other classes follow the guidelines of
another campaign option.
      </li>
    </ul>
    <h6><a id="learning-spells"></a>
Learning Spells
    </h6>
Learning spells, like casting them, exposes a character to unknowable
secrets and can damage and warp the mind. In this variant, whenever a
spellcaster learns a new spell, he loses Sanity points. In most cases, the
Sanity loss is equal to the spell�s level, but if the spell is included in
a tome of forbidden knowledge (see below), the loss can be greater.
    <p>
The rules for Sanity loss for learning spells work fine for wizards,
sorcerers, and other arcane spellcasting classes that learn spells one at
a time or in small groups, but this loss can easily overwhelm a cleric,
druid, or other divine spellcaster who gains a new spellcasting level
(thereby �learning� an entire level�s worth of new spells all at once). In
such a case, the player and the GM should work together to determine a
specific number of spells learned once access to a new spellcasting level
is gained. When in doubt about how many spells to allow a spellcaster to
learn at any given level, the spell acquisition rate of the wizard class
serves as a good baseline.
    </p>
    <p>
GMs wanting to add Sanity rules to a magic-rich campaign, or those wishing
to preserve more of the flavor of a normal d20 game, can allow any
spellcaster to learn a small number of spells at each level without losing
any Sanity.
    </p>
    <h6><a id="sanity-affecting-magic"></a>
Sanity-Affecting Magic
    </h6>
The following types of magic and specific spells have different or
additional effects when the Sanity variant is in use. For the effects of
healing spells and magical means of recovering sanity, see <a style={{
      "color": "#579eb6"
    }} href="#restoring-sanity-with-magic">Restoring
Sanity with Magic</a>, later in this section.
    <p><b><a id="fear-effects"></a>Fear Effects:</b>
Whenever a spell, creature, or other factor produces a fear effect that
causes its target to become <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#shaken">shaken</a>, <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#frightened">frightened</a>, or <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#panicked">panicked</a>, replace the
saving throw to avoid the effect (if applicable) with a Sanity check. On a
failed check (and sometimes even on a successful one), the subject loses a
number of Sanity points rather than experiencing the normal effect of the
magic. The table below provides a summary of the Sanity loss associated
with each spell that has the fear descriptor:
    </p>
    <b><a id="table-sanity-loss-from-fear-effects"></a>Table: Sanity Loss from Fear Effects</b>
    <table cellSpacing="0" style={{
      "width": "50%"
    }}>
      <thead>
        <tr>
          <th align="left">
Spell
          </th>
          <th>
Sanity Loss on
            <p>{`Failed Check`}</p>
          </th>
          <th>
Sanity Loss on
            <p>{`Successful Check`}</p>
          </th>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <td colSpan="3">
            <ol>
              <li>Bane works normally because its effect is less severe than
that of the shaken condition.
              </li>
              <li>Phantasmal killer and weird work normally because those spells
do not produce a shaken, frightened, or panicked effect. (The GM
could rule that a Sanity check takes the place of the Fortitude
save to avoid dying from fear; in such a case, failing the check
results in permanent insanity.)
              </li>
            </ol>
          </td>
        </tr>
      </tfoot>
      <tbody>
        <tr className="odd-row">
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="spellsAtoB.html#bane">Bane</a><sup><sub>1</sub></sup>
          </td>
          <td align="center">
�
          </td>
          <td align="center">
�
          </td>
        </tr>
        <tr>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="spellsC.html#cause-fear">Cause fear</a>
          </td>
          <td align="center">
1d6
          </td>
          <td align="center">
1
          </td>
        </tr>
        <tr className="odd-row">
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="spellsDtoE.html#doom">Doom</a>
          </td>
          <td align="center">
1d6
          </td>
          <td align="center">
�
          </td>
        </tr>
        <tr>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="spellsFtoG.html#fear">Fear</a>
          </td>
          <td align="center">
2d6
          </td>
          <td align="center">
1
          </td>
        </tr>
        <tr className="odd-row">
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="spellsPtoR.html#phantasmal-killer">Phantasmal killer</a><sup><sub>2</sub></sup>
          </td>
          <td align="center">
�
          </td>
          <td align="center">
�
          </td>
        </tr>
        <tr>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="spellsS.html#scare">Scare</a>
          </td>
          <td align="center">
1d6
          </td>
          <td align="center">
1
          </td>
        </tr>
        <tr className="odd-row">
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="spellsS.html#symbol-of-fear">Symbol of fear</a>
          </td>
          <td align="center">
2d6
          </td>
          <td align="center">
�
          </td>
        </tr>
        <tr>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="spellsTtoZ.html#weird">Weird</a><sup><sub>2</sub></sup>
          </td>
          <td align="center">
�
          </td>
          <td align="center">
            <p>{`�`}</p>
          </td>
        </tr>
      </tbody>
    </table>
    <p>
In this variant, <a style={{
        "color": "#579eb6"
      }} href="spellsPtoR.html#remove-fear">remove fear</a> does not automatically suppress an existing
fear effect on its subjects, but if it is cast on a creature that lost
Sanity because of a fear effect within the last 24 hours, that Sanity loss
is halved (to a minimum of 1 point) and the creature�s current Sanity is
adjusted accordingly.
    </p>
    <p><b><a id="illusions"></a>Illusions:</b>
Illusions, when they are believed, cause Sanity loss just as if the real
horrific monster or event were present. The GM can reduce the Sanity loss
caused by illusions (or eliminate it entirely) if such spells appear
frequently in the campaign.
    </p>
    <p><b><a id="bestow-curse"></a>Bestow Curse:</b>
When using the Sanity variant, <a style={{
        "color": "#579eb6"
      }} href="spellsAtoB.html#bestow-curse">this spell</a> can cause a Sanity check rather
than a Will save. If the victim fails the Sanity check, he loses 3d6
points of Sanity. Unlike normal Sanity loss, this number is also
subtracted from the character�s maximum Sanity. Magic that removes the
curse (such as <a style={{
        "color": "#579eb6"
      }} href="spellsPtoR.html#remove-curse">remove curse</a> or <a style={{
        "color": "#579eb6"
      }} href="spellsAtoB.html#break-enchantment">break enchantment</a>) can restore the
character�s normal maximum Sanity, but separate magic or the use of the
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#heal">Heal</a> skill is required to restore the character�s current Sanity.
    </p>
    <p><b><a id="contact-other-plane"></a>Contact Other Plane:</b>
When using the Sanity variant, characters casting <a style={{
        "color": "#579eb6"
      }} href="spellsC.html#contact-other-plane">this spell</a> risk a lost
of Sanity instead of Intelligence and Charisma. Whenever a character casts
this spell, she must make a Sanity check. If the check fails, the
character loses Sanity depending on the plane that the character was
trying to contact, according to the table below. Unlike the Intelligence
and Charisma reduction caused by the normal version of this spell, the
Sanity loss does not go away after a week � the loss is permanent until
restored by another spell or through the use of the <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#heal">Heal</a> skill.</p>
    <b><a id="table-sanity-loss-from-contact-other-plane"></a>Table: Sanity Loss from Contact Other Plane</b>
    <table cellSpacing="0" style={{
      "width": "30%"
    }}>
      <tr>
        <th align="left">
Plane
        </th>
        <th>
Sanity Loss
        </th>
      </tr>
      <tr className="odd-row">
        <td>
Elemental Plane (appropriate)
        </td>
        <td align="center">
1
        </td>
      </tr>
      <tr>
        <td>
Positive/Negative Energy Plane
        </td>
        <td align="center">
1
        </td>
      </tr>
      <tr className="odd-row">
        <td>
Astral Plane
        </td>
        <td align="center">
2
        </td>
      </tr>
      <tr>
        <td>
Outer Plane (demideity)
        </td>
        <td align="center">
1d6
        </td>
      </tr>
      <tr className="odd-row">
        <td>
Outer Plane (lesser deity)
        </td>
        <td align="center">
2d6
        </td>
      </tr>
      <tr>
        <td>
Outer Plane (intermediate deity)
        </td>
        <td align="center">
3d6
        </td>
      </tr>
      <tr className="odd-row">
        <td>
Outer Plane (greater deity)
        </td>
        <td align="center">
4d6
        </td>
      </tr>
    </table>
    <p><b><a id="commune"></a>Commune:</b>
When using the Sanity variant, replace <a style={{
        "color": "#579eb6"
      }} href="spellsC.html#commune">this spell</a>�s XP cost with a Sanity
check (1d6/3d6), made as a free action immediately after the spell�s
duration expires.
    </p>
    <p><b><a id="insanity"></a>Insanity:</b> Instead of experiencing <a style={{
        "color": "#579eb6"
      }} href="spellsHtoL.html#insanity">this spell</a>�s normal
effect, characters who fail the saving throw to resist the spell become
permanently insane as described in this variant (but suffer no Sanity
loss).
    </p>
    <p><b><a id="moment-of-prescience"></a>Moment of Prescience:</b>
In addition to the spell�s normal benefits, a character with an active
      <a style={{
        "color": "#579eb6"
      }} href="spellsMtoO.html#moment-of-prescience">moment of prescience</a> effect can make one Sanity check as if his current
Sanity equaled his maximum Sanity. The character need not use the effect
on the first Sanity check he is required to make, but he must choose
whether or not to use this benefit before making any Sanity check during
the spell�s duration.
    </p>
    <p><b><a id="status"></a>Status:</b>
In addition to <a style={{
        "color": "#579eb6"
      }} href="spellsS.html#status">the spell</a>�s normal effect, the caster can sense whenever
the subject suffers Sanity loss, temporary insanity, indefinite insanity,
or permanent insanity during the spell�s duration.
    </p>
    <p><b><a id="summon-monster"></a>Summon Monster:</b>
If a character summons a monster that causes Sanity loss by means of a
      <a style={{
        "color": "#579eb6"
      }} href="spellsS.html#summon-monster-i">summon monster</a>, <a style={{
        "color": "#579eb6"
      }} href="spellsS.html#summon-natures-ally-i">summon nature�s ally</a>, <a style={{
        "color": "#579eb6"
      }} href="spellsPtoR.html#planar-binding">planar binding</a>, or <a style={{
        "color": "#579eb6"
      }} href="spellsPtoR.html#planar-ally">planar ally</a>
spell, he suffers the usual Sanity loss for casting the spell and must
also make a Sanity check because of the monster�s presence.
    </p>
    <p><b><a id="symbol-of-insanity"></a>Symbol of Insanity:</b>
Instead of experiencing <a style={{
        "color": "#579eb6"
      }} href="spellsS.html#symbol-of-insanity">this spell</a>�s normal effect, characters who fail
the saving throw to resist the symbol become permanently insane as
described in this variant (but suffer no Sanity loss).
    </p>
    <h6><a id="reading-forbidden-tomes"></a>
Reading Forbidden Tomes
    </h6>
Obscure tomes add ranks to a character�s <a style={{
      "color": "#579eb6"
    }} href="#knowledge-forbidden-lore">Knowledge (forbidden lore)</a> skill
and teach arcane spells. Studying and comprehending these books causes all
that we know to become like shadows. The burning power of a greater
reality seizes the soul. Whether we try to retreat from the experience or
hunger greedily for more, it destroys our confidence in what we once
believed, opening us up to the all-encompassing truths of dark deities.
    <p>
For each such book encountered, the GM must set the examination period,
the <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#knowledge">Knowledge</a> (arcana) DC to understand it, the number of spells contained
in it, the Sanity loss that occurs upon beginning the examination, the
Sanity loss that occurs upon completion of the examination, and the ranks
of <a style={{
        "color": "#579eb6"
      }} href="#knowledge-forbidden-lore">Knowledge (forbidden lore)</a> gained from studying the book. While the GM
is free to set these parameters at any values that he feels are
appropriate for the campaign or adventure, <a style={{
        "color": "#579eb6"
      }} href="#table-example-forbidden-tomes">Table: Example Forbidden Tomes</a>
provides some suggested combinations of each of these parameters.
    </p>
    <b><a id="table-example-forbidden-tomes"></a>Table: Example Forbidden Tomes</b>
    <table cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tr>
        <th align="left">
Examination
          <p>{`Period`}</p>
        </th>
        <th>
Knowledge (arcana) DC
          <p>{`to Understand Tome`}</p>
        </th>
        <th>
Number of Spells
          <p>{`Contained in Tome`}</p>
        </th>
        <th>
          <p>{`Initial`}</p>
          <p>{`Sanity Loss`}</p>
        </th>
        <th>
Sanity Loss
          <p>{`upon Completion`}</p>
        </th>
        <th>
Knowledge (forbidden lore)
          <p>{`ranks gained`}</p>
        </th>
      </tr>
      <tr className="odd-row">
        <td>
1 week
        </td>
        <td align="center">
20
        </td>
        <td align="center">
0
        </td>
        <td align="center">
1
        </td>
        <td align="center">
1d4
        </td>
        <td align="center">
          <p>{`1`}</p>
        </td>
      </tr>
      <tr>
        <td>
1 week
        </td>
        <td align="center">
20
        </td>
        <td align="center">
1
        </td>
        <td align="center">
1d4
        </td>
        <td align="center">
1d4
        </td>
        <td align="center">
          <p>{`1`}</p>
        </td>
      </tr>
      <tr className="odd-row">
        <td>
1 week
        </td>
        <td align="center">
25
        </td>
        <td align="center">
2
        </td>
        <td align="center">
1d4
        </td>
        <td align="center">
2d6
        </td>
        <td align="center">
          <p>{`1`}</p>
        </td>
      </tr>
      <tr>
        <td>
2 weeks
        </td>
        <td align="center">
25
        </td>
        <td align="center">
1d4
        </td>
        <td align="center">
1d6
        </td>
        <td align="center">
2d6
        </td>
        <td align="center">
          <p>{`2`}</p>
        </td>
      </tr>
      <tr className="odd-row">
        <td>
2 weeks
        </td>
        <td align="center">
25
        </td>
        <td align="center">
1d6
        </td>
        <td align="center">
1d10
        </td>
        <td align="center">
2d6
        </td>
        <td align="center">
          <p>{`2`}</p>
        </td>
      </tr>
      <tr>
        <td>
2 weeks
        </td>
        <td align="center">
25
        </td>
        <td align="center">
3
        </td>
        <td align="center">
1d6
        </td>
        <td align="center">
2d6
        </td>
        <td align="center">
          <p>{`2`}</p>
        </td>
      </tr>
      <tr className="odd-row">
        <td>
2 weeks
        </td>
        <td align="center">
30
        </td>
        <td align="center">
1d6+1
        </td>
        <td align="center">
1d6
        </td>
        <td align="center">
2d6
        </td>
        <td align="center">
          <p>{`3`}</p>
        </td>
      </tr>
      <tr>
        <td>
3 weeks
        </td>
        <td align="center">
20
        </td>
        <td align="center">
1d4+1
        </td>
        <td align="center">
1d10
        </td>
        <td align="center">
2d6
        </td>
        <td align="center">
          <p>{`2`}</p>
        </td>
      </tr>
      <tr className="odd-row">
        <td>
3 weeks
        </td>
        <td align="center">
25
        </td>
        <td align="center">
1d6
        </td>
        <td align="center">
1d6
        </td>
        <td align="center">
2d10
        </td>
        <td align="center">
          <p>{`2`}</p>
        </td>
      </tr>
      <tr>
        <td>
3 weeks
        </td>
        <td align="center">
30
        </td>
        <td align="center">
1d4+5
        </td>
        <td align="center">
1d10
        </td>
        <td align="center">
3d6
        </td>
        <td align="center">
          <p>{`3`}</p>
        </td>
      </tr>
    </table>
    <h5><a id="sanity-resistance"></a>
SANITY RESISTANCE
    </h5>
    <div style={{
      "marginLeft": "40px"
    }}>The Sanity mechanic was originally created to mimic the effect that the
unspeakable horrors of the Cthulhu Mythos would have on normal folk from a
world much like our own. Since d20 characters live in a world of magic and
monsters, however, the GM might want to make them less susceptible to
Sanity loss caused by encountering strange creatures (see <a style={{
        "color": "#579eb6"
      }} href="#table-sanity-loss-from-creatures">Table: Sanity
Loss from Creatures</a>) by allowing them to have a measure of Sanity
resistance, which is tied to one of two attributes.
      <p>
Each character can be allowed to have Sanity resistance equal to his
character level. Alternatively, each character can be allowed to have
Sanity resistance equal to his Wisdom modifier. (Obviously, the second
alternative will produce lower Sanity resistance figures in most cases.)
This number is the amount of Sanity loss a character can ignore when he
encounters a creature that requires a Sanity check.
      </p>
      <p>
The GM may decide that Sanity resistance also applies to certain kinds of
severe shocks (although it might not apply to personally horrific
experiences, such as seeing a close friend die) and to the casting or
learning of spells.
      </p></div>
    <h5><a id="getting-used-to-awfulness"></a>
GETTING USED TO AWFULNESS
    </h5>
Never underestimate the ability of the sentient mind to adapt, even to the
most horrific experiences. Reading and rereading the same bit of
disturbing text or seeing the same horrible image over and over eventually
provokes no further loss of Sanity. Within a reasonable interval of play,
usually a single session of the game, characters should not lose more
Sanity points for seeing monsters of a particular sort than the maximum
possible points a character could lose from seeing one such monster. For
instance, the Sanity loss for seeing a single human <a style={{
      "color": "#579eb6"
    }} href="monstersTtoZ.html#zombie">zombie</a> is 1/1d6. Thus,
in the same game day or in the same play session, no character should lose
more than 6 Sanity points for seeing any number of zombies. Keep in mind
that the interpretation of �reasonable interval� must vary by GM and
situation. When it feels right, the GM should rule that the horror is
renewed and points must be lost again.
    <p>
Learning or casting spells never becomes a normal occurrence. No matter
how many times a character casts a spell, no matter what the time interval
between castings may be, the Sanity loss is always the same. This point is
also true for anything that a character does willingly. For example, if
brutally murdering a friend costs 2/1d10 Sanity, this loss is incurred
each time, even if the character loses the maximum possible points (10)
after the first such murder he commits.
    </p>
    <h5><a id="types-of-insanity"></a>
TYPES OF INSANITY
    </h5>
Character insanity is induced by a swift succession of shocking
experiences or ghastly revelations, events usually connected with dark
gods, creatures from the Outer Planes, or powerful spellcasting.
    <p>
Horrifying encounters can result in one of three states of mental
unbalance: temporary, indefinite, and permanent insanity. The first two,
temporary insanity and indefinite insanity, can be cured. The third,
permanent insanity, results when a character�s Sanity points are reduced
to -10 or lower. This condition cannot be cured.
    </p>
    <h6><a id="temporary-insanity"></a>
Temporary Insanity
    </h6>
Whenever a character loses Sanity points equal to one-half her Wisdom
score from a single episode of Sanity loss, she has experienced enough of
a shock that the GM must ask for a Sanity check. If the check fails, the
character realizes the full significance of what she saw or experienced
and goes temporarily insane. If the check succeeds, the character does not
go insane, but she may not clearly remember what she experienced (a trick
the mind plays to protect itself).
    <p>
Temporary insanity might last for a few minutes or a few days. Perhaps the
character acquires a phobia or fetish befitting the situation, faints,
becomes hysterical, or suffers nervous twitches, but she can still respond
rationally enough to run away or hide from a threat.
    </p>
    <p>
A character suffering from temporary insanity remains in this state for
either a number of rounds or a number of hours; roll d% and consult <a style={{
        "color": "#579eb6"
      }} href="#table-duration-of-temporary-insanity">Table:
Duration of Temporary Insanity</a> to see whether the insanity is
short-term or long-term. After determining the duration of the insanity,
roll d% and consult either <a style={{
        "color": "#579eb6"
      }} href="#table-short-term-temporary-insanity-effects">Table: Short-Term Temporary Insanity Effects</a>
or <a style={{
        "color": "#579eb6"
      }} href="#table-long-term-temporary-insanity-effects">Table: Long-Term Temporary Insanity Effects</a> to identify the specific
effect of the insanity. The GM must describe the effect so that the player
can roleplay it accordingly. Ideas for phobias and episodes of insanity
are described later in this chapter.
    </p>
    <p>
Successful application of the <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#heal">Heal</a> skill (see <a style={{
        "color": "#579eb6"
      }} href="#the-heal-skill-and-mental-treatment">The Heal Skill and Mental
Treatment</a>, below) may alleviate or erase temporary insanity.
    </p>
    <p>
Temporary insanity ends either when the duration rolled on <a style={{
        "color": "#579eb6"
      }} href="#table-duration-of-temporary-insanity">Table: Duration of Temporary Insanity</a> has
elapsed, or earlier if the GM considers it appropriate to do so.
    </p>
    <p>
After an episode of temporary insanity ends, traces or even profound
evidence of the experience should remain. No reason exists why, for
instance, a phobia should depart from someone�s mind as quickly as a
warrior draws his sword. What remains behind after a brief episode of
temporary insanity should exert a pervasive influence on the character.
The character may still be a bit batty, but her conscious mind once again
runs the show.
    </p>
    <p>
As a variant rule, if the amount of Sanity lost exceeds the character�s
current Wisdom score, consider the temporary insanity to always be of the
long-term variety.
    </p>
    <table style={{
      "width": "100%"
    }}>
      <tr>
        <td style={{
          "width": "48%"
        }}>
          <b><a id="table-duration-of-temporary-insanity"></a>Table: Duration Of Temporary Insanity</b>
          <table cellPadding="0" cellSpacing="0" style={{
            "width": "100%"
          }}>
            <tr>
              <th>
d%
              </th>
              <th>
Temporary Insanity Type
              </th>
              <th>
Duration
              </th>
            </tr>
            <tr className="odd-row">
              <td>
01-80
              </td>
              <td>
Short-term
              </td>
              <td>
1d10+4 rounds
              </td>
            </tr>
            <tr>
              <td>
81-100
              </td>
              <td>
Long-term
              </td>
              <td>
1d10�10 hours
              </td>
            </tr>
          </table>
          <b><a id="table-short-term-temporary-insanity-effects"></a>Table: Short-Term Temporary Insanity Effects</b>
          <table cellPadding="0" cellSpacing="0" style={{
            "width": "100%"
          }}>
            <tr>
              <th>
d%
              </th>
              <th>
Effect
              </th>
            </tr>
            <tr className="odd-row">
              <td align="center">
01-20
              </td>
              <td>
Character faints (can be awakened by vigorous action taking 1 round;
thereafter, character is shaken until duration expires).
              </td>
            </tr>
            <tr>
              <td align="center">
21-30
              </td>
              <td>
Character has a screaming fit.
              </td>
            </tr>
            <tr className="odd-row">
              <td align="center">
31-40
              </td>
              <td>
Character flees in panic.
              </td>
            </tr>
            <tr>
              <td align="center">
41-50
              </td>
              <td>
Character shows physical hysterics or emotional outburst (laughing,
crying, and so on).
              </td>
            </tr>
            <tr className="odd-row">
              <td align="center">
51-55
              </td>
              <td>
Character babbles in incoherent rapid speech or in logorrhea (a
torrent of coherent speech).
              </td>
            </tr>
            <tr>
              <td align="center">
56-60
              </td>
              <td>
Character gripped by intense phobia, perhaps rooting her to the spot.
              </td>
            </tr>
            <tr className="odd-row">
              <td align="center">
61-65
              </td>
              <td>
Character becomes homicidal, dealing harm to nearest person as
efficiently as possible.
              </td>
            </tr>
            <tr>
              <td align="center">
66-70
              </td>
              <td>
Character has hallucinations or delusions (details at the discretion
of the GM).
              </td>
            </tr>
            <tr className="odd-row">
              <td align="center">
71-75
              </td>
              <td>
Character gripped with echopraxia or echolalia (saying or doing
whatever those nearby say or do).
              </td>
            </tr>
            <tr>
              <td align="center">
76-80
              </td>
              <td>
Character gripped with strange or deviant eating desire (dirt, slime,
cannibalism, and so on).
              </td>
            </tr>
            <tr className="odd-row">
              <td align="center">
81-90
              </td>
              <td>
Character falls into a stupor (assumes fetal position, oblivious to
events around her).
              </td>
            </tr>
            <tr>
              <td align="center">
91-99
              </td>
              <td>
Character becomes catatonic (can stand but has no will or interest;
may be led or forced to simple actions but takes no independent
action).
              </td>
            </tr>
            <tr className="odd-row">
              <td align="center">
100
              </td>
              <td>
Roll on Table 6-10: Long-Term Temporary Insanity Effects.
              </td>
            </tr>
          </table>
        </td>
        <td style={{
          "width": "4%"
        }}></td>
        <td valign="top" style={{
          "width": "48%"
        }}>
          <b><a id="table-long-term-temporary-insanity-effects"></a>Table: Long-Term Temporary Insanity Effects</b>
          <table cellPadding="0" cellSpacing="0" style={{
            "width": "100%"
          }}>
            <tr>
              <th>
d%
              </th>
              <th>
Effect
              </th>
            </tr>
            <tr className="odd-row">
              <td align="center">
01-10
              </td>
              <td>
Character performs compulsive rituals (washing hands constantly,
praying, walking in a particular rhythm, never stepping on cracks,
constantly checking to see if crossbow is loaded, and so on).
              </td>
            </tr>
            <tr>
              <td align="center">
11-20
              </td>
              <td>
Character has hallucinations or delusions (details at the discretion
of the GM).
              </td>
            </tr>
            <tr className="odd-row">
              <td align="center">
21-30
              </td>
              <td>
Character becomes paranoid.
              </td>
            </tr>
            <tr>
              <td align="center">
31-40
              </td>
              <td>
Character gripped with severe phobia (refuses to approach object of
phobia except on successful DC 20 Will save).
              </td>
            </tr>
            <tr className="odd-row">
              <td align="center">
41-45
              </td>
              <td>
Character has aberrant sexual desires (exhibitionism, nymphomania or
satyriasis, teratophilia, necrophilia, and so on).
              </td>
            </tr>
            <tr>
              <td align="center">
46-55
              </td>
              <td>
Character develops an attachment to a �lucky charm� (embraces object,
type of object, or person as a safety blanket) and cannot function
without it.
              </td>
            </tr>
            <tr className="odd-row">
              <td align="center">
56-65
              </td>
              <td>
Character develops psychosomatic blindness, deafness, or the loss of
the use of a limb or limbs.
              </td>
            </tr>
            <tr>
              <td align="center">
66-75
              </td>
              <td>
Character has uncontrollable tics or tremors (-4 penalty on all attack
rolls, checks, and saves, except those purely mental in nature).
              </td>
            </tr>
            <tr className="odd-row">
              <td align="center">
76-85
              </td>
              <td>
Character has amnesia (memories of intimates usually lost first;
Knowledge skills useless).
              </td>
            </tr>
            <tr>
              <td align="center">
86-90
              </td>
              <td>
Character has bouts of reactive psychosis (incoherence, delusions,
aberrant behavior, and/or hallucinations).
              </td>
            </tr>
            <tr className="odd-row">
              <td align="center">
91-95
              </td>
              <td>
Character loses ability to communicate via speech or writing.
              </td>
            </tr>
            <tr>
              <td align="center">
96-100
              </td>
              <td>
Character becomes catatonic (can stand but has no will or interest;
may be led or forced into simple actions but takes no independent
action).
              </td>
            </tr>
          </table>
        </td></tr></table>
    <h6><a id="indefinite-insanity"></a>
Indefinite Insanity
    </h6>
    <p>
If a character loses 20% (one-fifth) or more of her current Sanity points
in the space of 1 hour, she goes indefinitely insane. The GM judges when
the impact of events calls for such a measure. Some GMs never apply the
concept to more than the result of a single roll, since this state can
remove characters from play for extended periods. An episode of indefinite
insanity lasts for 1d6 game months (or as the GM dictates). Symptoms of
indefinite insanity may not be immediately apparent (which may give the GM
additional time to decide what the effects of such a bout of insanity
might be).
    </p>
    <p>
      <a style={{
        "color": "#579eb6"
      }} href="#table-random-indefinite-insanity">Table: Random Indefinite Insanity</a> is provided as an aid to selecting
what form a character�s indefinite insanity takes. (The mental disorders
mentioned on this table are explained later in this section.) Many GMs
prefer to choose an appropriate way for the insanity to manifest, based on
the circumstances that provoked it. It�s also a good idea to consult with
the player of the afflicted character to see what sort of mental malady
the player wishes to roleplay.
    </p>
    <p>
The state of indefinite insanity is encompassing and incapacitating. For
instance, a schizophrenic may be able to walk the streets while babbling
and gesticulating, find rudimentary shelter, and beg for enough food to
survive, but most of the business of the mind has departed into itself:
She cannot fully interact with friends, family, and acquaintances.
Conversation, cooperation, and all sense of personal regard have vanished
from her psyche.
    </p>
    <p>
It is possible for characters with indefinite insanity to continue to be
played as active characters, depending on the form their madness takes.
The character may still attempt to stumble madly through the rest of an
adventure. However, with her weakened grasp on reality, she is most likely
a danger to herself and others.
    </p>
    <p>
As a general rule, a character suffering from indefinite insanity should
be removed from active play until she recovers. At the GM�s discretion,
the player of the character might be allowed to use a temporary character
until the end of the story. Whether this �stand-in� character is an
incidental NPC in the adventure, a character of the same level as the rest
of the group, one or two levels below the rest of the characters, or even
a 1st-level character, is up to the GM. Different GMs have different ways
of handling this transition.
    </p>
    <p>
If a character goes indefinitely insane near the end of an adventure, the
GM may decide to set the next adventure to begin after the insane
character has recovered.
    </p>
    <p>
Characters suffering from indefinite insanity are in limbo, unable to help
themselves or others. The <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#heal">Heal</a> skill can be used to restore Sanity points
during this period, but the underlying insanity remains.
    </p>
    <p>
After recovery, a victim retains definite traces of madness. For example,
even though a character knows he is no longer insane, she might be deathly
afraid of going to sleep if her insanity manifested itself in the form of
terrifying nightmares. The character is in control of her actions, but the
experience of insanity has changed her, perhaps forever.
    </p>
    <b><a id="table-random-indefinite-insanity"></a>Table: Random Indefinite Insanity</b>
    <table cellPadding="0" cellSpacing="0" style={{
      "width": "40%"
    }}>
      <tr>
        <th>
d%
        </th>
        <th align="left">
Mental Disorder Type
        </th>
      </tr>
      <tr className="odd-row">
        <td>
01�15
        </td>
        <td>
Anxiety (includes severe phobias)
        </td>
      </tr>
      <tr>
        <td>
16�20
        </td>
        <td>
Dissociative (amnesia, multiple personalities)
        </td>
      </tr>
      <tr className="odd-row">
        <td>
21�25
        </td>
        <td>
Eating (anorexia, bulimia)
        </td>
      </tr>
      <tr>
        <td>
26�30
        </td>
        <td>
Impulse control (compulsions)
        </td>
      </tr>
      <tr className="odd-row">
        <td>
31�35
        </td>
        <td>
Mood (manic/depressive)
        </td>
      </tr>
      <tr>
        <td>
36�45
        </td>
        <td>
Personality (various neuroses)
        </td>
      </tr>
      <tr className="odd-row">
        <td>
46�50
        </td>
        <td>
Psychosexual (sadism, nymphomania)
        </td>
      </tr>
      <tr>
        <td>
51�55
        </td>
        <td>
Psychospecies
        </td>
      </tr>
      <tr className="odd-row">
        <td>
56�70
        </td>
        <td>
Schizophrenia/psychotic (delusions,
hallucinations, paranoia, catatonia)
        </td>
      </tr>
      <tr>
        <td>
71�80
        </td>
        <td>
Sleep (night terrors, sleepwalking)
        </td>
      </tr>
      <tr className="odd-row">
        <td>
81�85
        </td>
        <td>
Somatoform (psychosomatic conditions)
        </td>
      </tr>
      <tr>
        <td>
86�95
        </td>
        <td>
Substance abuse (alcoholic, drug addict)
        </td>
      </tr>
      <tr className="odd-row">
        <td>
96�100
        </td>
        <td>
Other (megalomania, quixotism, panzaism)
        </td>
      </tr>
    </table>
    <h6><a id="permanent-insanity"></a>
Permanent Insanity
    </h6>
A character whose Sanity score falls to -10 goes permanently insane. The
character becomes an NPC under the control of the GM.
    <p>
A character with permanent insanity may be reduced to a raving lunatic or
may be outwardly indistinguishable from a normal person; either way, she
is inwardly corrupted by the pursuit of knowledge and power. Some of the
most dangerous cultists in the world are characters who have become
permanently insane, been corrupted by forbidden knowledge, and �gone over
to the other side.�
    </p>
    <p>
A character might be driven permanently insane by forces other than dark
gods or forbidden knowledge. In such cases, moral corruption need not
necessarily occur. The GM might decide to consider different sorts of
permanent insanity, rolling randomly or choosing from among the mental
disorders on <a style={{
        "color": "#579eb6"
      }} href="#table-random-indefinite-insanity">Table: Random Indefinite Insanity</a>, above.
    </p>
    <p>
A character who has gone permanently insane can never be normal again (in
some campaigns, a permanently insane character can be cured with the aid
of powerful magic). She is forever lost in her own world. This need not
mean a lifetime locked away from society, merely that the character has
retreated so far from reality that normal mental functions can never be
restored. She might be able to lead, within restricted bounds, a more or
less normal life if kept away from the stimulus that triggers strong
responses in her individual case. Yet a relapse may come quickly. Her calm
facade can be destroyed in seconds if even the smallest reminder of what
it was that drove her mad disturbs her fragile equilibrium. In any event,
the eventual fate of a permanently insane character is a matter for
individual GM and players to decide.
    </p>
    <h5><a id="variant-rule-insane-insight"></a>
VARIANT RULE: INSANE INSIGHT
    </h5>
At the GM�s option, a character who has just gone insane may have an
insight into the situation or entity that provoked the insanity. The
player needs to make a DC 15 Wisdom check to gain the insight. Information
provided by this sudden burst of awareness is up to the GM, but it may
include something about a creature�s origin or a fact about its nature
(feeding habits, natural habitat, weakness), a clue to the identity of a
murderer at a murder scene, or some hint at a location of great
importance.
    <h5><a id="gaining-or-recovering-sanity"></a>
GAINING OR RECOVERING SANITY
    </h5>
A character�s Sanity score can increase during the events of a campaign.
Although a character�s Sanity score can never exceed 99 minus her
    <a style={{
      "color": "#579eb6"
    }} href="#knowledge-forbidden-lore">Knowledge (forbidden lore)</a> ranks, her maximum Sanity and current Sanity
can exceed her starting Sanity.
    <p><b><a id="level-advancement"></a>Level Advancement:</b>
A character�s current Sanity can become higher than her starting Sanity as
a result of gained levels: Whenever a character gains a new level, she
rolls 1d6 and adds the result to her current Sanity. Some GMs may feel
such self-improvement to be antithetical to this variant�s dark tone, and
thus may not allow it. Others may allow it if the player can roll over her
character�s current Sanity points after the character gains a level. Most
Game Masters should find the question to be of no consequence, since
characters continue to go insane regardless of how many Sanity points they
gain. This is a point for players to be aware of, but not to worry about.
    </p>
    <p><b><a id="sotry-awards"></a>Story Awards:</b>
The GM may decide to award increases in character�s current Sanity if they
foil a great horror, a demonic plan, or some other nefarious enterprise.
    </p>
    <h6><a id="mental-therapy"></a>
Mental Therapy
    </h6>
To give useful mental therapy, a therapist must have the <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#heal">Heal</a> skill.
Intensive treatment can return Sanity points to a troubled character.
However, Sanity points restored in this manner can never cause the
patient�s Sanity score to exceed her starting Sanity or maximum Sanity,
whichever is lower. A character can have only one healer at a time. See
    <a style={{
      "color": "#579eb6"
    }} href="#the-heal-skill-and-mental-treatment">The Heal Skill and Mental Treatment</a> sidebar for a detailed description of
how this works.
    <p>
Such treatment can also be used to help a character snap out of an episode
of <a style={{
        "color": "#579eb6"
      }} href="#temporary-insanity">temporary insanity</a> (for example, from an acute panic attack). It does
not speed recovery from indefinite insanity, but it can strengthen a
character by increasing her Sanity points.
    </p>
    <p>
Recovery from <a style={{
        "color": "#579eb6"
      }} href="#indefinite-insanity">indefinite insanity</a> only comes with time (typically, 1d6
months). It is not dependent upon the character�s Sanity points and is not
connected to them. A character can be sane with 24 Sanity points and
insane while possessing 77 Sanity points.
    </p>
    <h6><a id="the-heal-skill-and-mental-treatment"></a>
The Heal Skill And Mental Treatment
    </h6>
    <div style={{
      "marginLeft": "40px"
    }}>The Sanity rules presented here provide a new use for the <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#heal">Heal</a> skill,
allowing trained healers to help characters recover lost Sanity points.
The DC and effect of a Heal check made to restore lost Sanity depend on
whether the therapist is trying to offer immediate care or long-term care.
      <p><i><a id="immediate-care"></a>Immediate Care:</i>
When someone suffers an episode of <a style={{
          "color": "#579eb6"
        }} href="#temporary-insanity">temporary insanity</a>, a therapist can
bring him out of it � calming his terror, snapping him out of his stupor, or
doing whatever else is needed to restore the patient to the state she was
in before the temporary insanity � by making a DC 15 <a style={{
          "color": "#579eb6"
        }} href="skillsAll.html#heal">Heal</a> check as a
full-round action.
      </p>
      <p>
A therapist can also use immediate care to stabilize the Sanity score of a
character whose current Sanity is between -1 and -9. On a successful DC 15
check (requiring a full-round action), the character�s Sanity score
improves to 0.
      </p>
      <p><i><a id="long-term-care"></a>Long-Term Care:</i>
Providing long-term care means treating a mentally disturbed person for a
day or more in a place away from stress and distractions. A therapist must
spend 1d4 hours per day doing nothing but talking to the patient. If the
therapist makes a DC 20 <a style={{
          "color": "#579eb6"
        }} href="skillsAll.html#heal">Heal</a> check at the end of this time, the patient
recovers 1 Sanity point. A therapist can tend up to six patients at a
time; each patient beyond the first adds 1 hour to the total time per day
that must be devoted to therapy. The check must be made each day for each
patient. A roll of 1 on any of these Heal checks indicates that the
patient loses 1 point of Sanity that day, as she regresses mentally due to
horrors suddenly remembered.
      </p>
      <p><b><a id="variant-knowledge-mental-therapy"></a>Variant�Knowledge (Mental Therapy):</b>
A new skill called Knowledge (mental therapy) can serve as the primary way
to treat those who have suffered Sanity loss. Knowledge (mental therapy)
is a Wisdom-based skill that cannot be used untrained. If you use this
variant, characters with the <a style={{
          "color": "#579eb6"
        }} href="skillsAll.html#heal">Heal</a> skill can only offer immediate care, and
cannot offer long-term care. The Knowledge (mental therapy) skill allows
both types of treatment.
      </p>
      <p><i>Note:</i>
If magical means of restoring Sanity are present in the campaign, the
Knowledge (mental therapy) skill is generally not worth including as a
separate skill, because characters are better off simply using magic
rather than devoting precious skill points to such a narrow-focus skill.
If magic cannot restore Sanity, the ability to restore Sanity by other
means is much more important, and Knowledge (mental therapy) should
probably exist as a separate skill.
      </p></div>
    <h6><a id="restoring-sanity-with-magic"></a>
Restoring Sanity with Magic
    </h6>
The way that Sanity loss and magic healing interact can greatly affect the
feel of your game. At one extreme, the GM can rule that magic can easily
cure Sanity loss, in which case Sanity becomes little more than a
specialized version of �mental hit points� that includes some neat side
effects (insanity). In such a case, characters can usually restore
themselves to full Sanity with a day or two of rest and spellcasting.
    <p>
At the other extreme, the campaign might be structured so that magical
healing can do little or nothing to restore Sanity, and even powerful
divine spellcasters capable of curing the most deadly physical malady shy
away from those encounters that might drain away Sanity points. The spells
that can potentially restore Sanity points are discussed below. The GM
should feel free to choose which of these spell effects are present in the
game, but once established, these effects should not be changed in
mid-campaign.
    </p>
    <p><b><a id="atonement"></a>Atonement:</b>
Although <a style={{
        "color": "#579eb6"
      }} href="spellsAtoB.html#atonement">this spell</a> does not usually restore Sanity, it can be used in
those rare cases when a character�s own actions inadvertently lead to an
evil act that causes the character to lose Sanity points. If a quest or
geas is combined with the atonement spell, Sanity points are not restored
until the task is completed. A successful use of the atonement spell can
restore all Sanity lost through the direct result of the evil acts for
which the character atones.
    </p>
    <p><b><a id="calm-emotions"></a>Calm Emotions:</b>
      <a style={{
        "color": "#579eb6"
      }} href="spellsC.html#calm-emotions">This spell</a> cannot restore Sanity directly, but it can temporarily mitigate
the effects of <a style={{
        "color": "#579eb6"
      }} href="#temporary-insanity">temporary</a> or <a href="#permanent-insanity">permanent insanity</a>. While the spell is in
effect, the targets act calmly and ignore behavior changes caused by
Sanity loss.
    </p>
    <p><b><a id="heal"></a>Heal:</b>
In addition to its normal effects, <a style={{
        "color": "#579eb6"
      }} href="spellsHtoL.html#heal">heal</a> restores 10 Sanity points and
removes all forms of <a style={{
        "color": "#579eb6"
      }} href="#temporary-insanity">temporary insanity</a>.
    </p>
    <p><b><a id="mind-blank"></a>Mind Blank:</b>
While <a style={{
        "color": "#579eb6"
      }} href="spellsMtoO.html#mind-blank">the spell</a> is in effect, the subject is immune to Sanity loss.
    </p>
    <p><b><a id="miracle"></a>Miracle:</b>
      <a style={{
        "color": "#579eb6"
      }} href="spellsMtoO.html#miracle">This spell</a> can restore a character to maximum Sanity even if his current
Sanity has dropped to -10. Miracle even heals <a style={{
        "color": "#579eb6"
      }} href="#permanent-insanity">permanent insanity</a>.
    </p>
    <p><b><a id="restoration"></a>Restoration:</b>
If the caster chooses, <a style={{
        "color": "#579eb6"
      }} href="spellsPtoR.html#restoration">restoration</a> can restore 1d6 Sanity points per two
levels to the target creature (max 5d6) instead of having its normal
effect.
    </p>
    <p><b><a id="restoration-greater"></a>Restoration, Greater:</b>
If the caster chooses, <a style={{
        "color": "#579eb6"
      }} href="spellsPtoR.html#greater-restoration">greater restoration</a> can restore the target creature
to its maximum Sanity instead of having its normal effect.
    </p>
    <p><b><a id="restoration-lesser"></a>Restoration, Lesser:</b>
If the caster chooses, <a style={{
        "color": "#579eb6"
      }} href="spellsPtoR.html#lesser-restoration">lesser restoration</a> can restore 1d4 Sanity points to
the subject instead of having its normal effect.
    </p>
    <p><b><a id="wish"></a>Wish:</b>
      <a style={{
        "color": "#579eb6"
      }} href="spellsTtoZ.html#wish">This spell</a> can restore a character to maximum Sanity even if his current
Sanity has dropped to -10. Wish even heals permanent insanity.
    </p>
    <p><b><a id="wish-limited"></a>Wish, Limited:</b>
      <a style={{
        "color": "#579eb6"
      }} href="spellsHtoL.html#limited-wish">This spell</a> can restore a character to maximum Sanity even if his current
Sanity has dropped to -10. Limited wish does not heal permanent insanity.
    </p>
    <h6><a id="alchemical-treatments"></a>
Alchemical Treatments
    </h6>
In the real world, psychiatric drugs play a key role in the modern
treatment of many mental disorders. Although psychiatric drugs were
administered to patients in the early part of the 20th century, only in
the 1940s and later were they broadly and consistently effective in
treating the symptoms of emotional trauma.
    <p>
In a fantasy game, the GM should decide whether rare herbs and alchemical
substances can provide the same benefits that modern psychiatric drugs can
deliver. It is quite believable, for example, that a group of wizards discovered alchemical means of
isolating substances that affect the mind in profound ways, offering many
of the same benefits of modern medicine. Therefore, the GM need only
decide how easily he wants characters to overcome Sanity loss and insanity
effects. Once the desired tone of the campaign is known, the GM can
determine whether alchemical treatment is available. The Sanity variant
assumes that skilled alchemists can create substances that offer the same
benefits that psychiatric drugs can provide.
    </p>
    <p>
As long as a character can afford the correct herbs and alchemical
substances and is able to ingest them, the symptoms of indefinite insanity
can be ignored. Ingesting these alchemical substances and drugs does not
make a character immune or even particularly resistant to further Sanity
losses. A DC 25 <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#craft">Craft</a> (alchemy) check is needed to accurately prepare the
correct herbs and substances and administer the correct dosage.
    </p>
    <p>
Long-term alchemical treatment can restore lost Sanity points, just as use
of the <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#heal">Heal</a> skill can. For each month the character takes an accurately
prescribed psychiatric medication, she regains 1d3 Sanity points. As with
treatment through the Heal skill, long-term drug therapy can never raise a
character�s current Sanity above her starting Sanity.
    </p>
    <p>
A character cannot regain Sanity from both treatment with the <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#heal">Heal</a> skill
and alchemical treatment in the same month.
    </p>
    <h6><a id="drugs-and-sanity"></a>
Drugs and Sanity
    </h6>
Drugs in the d20 game follow many of the same rules as poisons, allowing
the imbiber saving throws to resist their initial and secondary effects.
    <a style={{
      "color": "#579eb6"
    }} href="spellsDtoE.html#delay-poison">Delay poison</a>, <a style={{
      "color": "#579eb6"
    }} href="spellsMtoO.html#neutralize-poison">neutralize poison</a>, and similar effects negate or end a
drug�s effects, but they do not restore hit points, ability damage, or
other damage caused by the substance.
    <p>
A creature that willingly takes a drug automatically fails both saving
throws. It is not possible to intentionally fail the initial save but
attempt to save against the secondary effect, or vice versa. Save DCs are
provided for situations in which a character is unwillingly drugged.
    </p>
    <p>
Drugs also have some uses in treating insanity. The drugs described below
all affect a character�s Sanity to some extent.
    </p>
    <p>
For more details on drugs and advice on appropriate inclusion of drugs in
a campaign, see Book of Vile Darkness or Lords of Darkness.
    </p>
    <h6><a id="sample-drugs"></a>
Sample Drugs
    </h6>
Below are examples of drugs that can be introduced into a campaign; many
of them are effective against some sorts of mental disorders. <a style={{
      "color": "#579eb6"
    }} href="#table-drugs">Table: Drugs</a>
provides some specifics for each drug, and the text description for each
one provides the following additional information.
    <p><b>Initial Effect:</b>
The effect of the drug if the initial Fortitude saving throw is failed.
    </p>
    <p><b>Secondary Effect:</b>
The effect of the drug if the secondary Fortitude saving throw is failed 1
minute after ingestion.
    </p>
    <p><b>Side Effect:</b>
The side effect (if any) that occurs immediately upon taking the drug.
    </p>
    <p><b>Overdose:</b>
What (if anything) constitutes an overdose and its effect on the
character.
    </p>
    <b><a id="table-drugs"></a>Table: Drugs</b>
    <table cellSpacing="0" cellPadding="0" style={{
      "width": "40%"
    }}>
      <thead>
        <tr>
          <th align="left">
Name
          </th>
          <th align="left">
Type
          </th>
          <th>
Price
          </th>
          <th>
Craft
            <p>{`(Alchemy)`}</p>
            <p>{`DC`}</p>
          </th>
          <th align="left">
            <p>{`Addiction`}</p>
            <p>{`Rating`}<sup>{`1`}</sup></p>
          </th>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <td colSpan="5">
            <ol>
              <li>Discussed in the following section.
              </li>
            </ol>
          </td>
        </tr>
      </tfoot>
      <tbody>
        <tr className="odd-row">
          <td>
Arthorvin
          </td>
          <td>
Ingested DC 12
          </td>
          <td align="center">
5 gp
          </td>
          <td align="center">
25
          </td>
          <td>
Low
          </td>
        </tr>
        <tr>
          <td>
Carthagu
          </td>
          <td>
Ingested DC 13
          </td>
          <td align="center">
5 gp
          </td>
          <td align="center">
25
          </td>
          <td>
            <p>{`Low`}</p>
          </td>
        </tr>
        <tr className="odd-row">
          <td>
Mertoran leaf
          </td>
          <td>
Ingested DC 13
          </td>
          <td align="center">
10 gp
          </td>
          <td align="center">
25
          </td>
          <td>
Negligible
          </td>
        </tr>
        <tr>
          <td>
Zixalix
          </td>
          <td>
Ingested DC 16
          </td>
          <td align="center">
15 gp
          </td>
          <td align="center">
25
          </td>
          <td>
Medium
          </td>
        </tr>
      </tbody>
    </table>
    <p><b><a id="arthorvin"></a>Arthorvin</b>
      <p>{`A fragrant gray powder made from rare magical plants, the drug called
arthorvin is an appropriate alchemical treatment for anxiety, mood
disorders, and dissociative disorders.`}</p>
      <i>Initial Effect:</i>
1 point of <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#ability-damaged">Charisma damage</a>.
      <i>Secondary Effect:</i>
The user is in a calm, peaceful mental state for 2d4 hours and gains a +1
alchemical bonus on Will saves.
      <i>Side Effect:</i>
Arthorvin causes a general dulling of the imbiber�s emotions and
reactions. While the drug is in effect, the user takes a -1 penalty on all
initiative checks.
      <i>Overdose:</i>
n/a.</p>
    <p><b><a id="carthagu"></a>Carthagu</b>
      <p>{`Carthagu is a thin green tea served warm. It is an appropriate alchemical
treatment for impulse control problems, eating disorders, and sleep
disorders.`}</p>
      <i>Initial Effect:</i>
2 points of <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#ability-damaged">Strength damage</a>.
      <i>Secondary Effect:</i>
Carthagu soothes a wide range of mental disorders, providing relief from
the symptoms of the indicated disorders for up to 8 hours.
      <i>Side Effect:</i>
n/a.
      <i>Overdose:</i>
n/a.</p>
    <p><b><a id="mertoran-leaf"></a>Mertoran Leaf</b>
      <p>{`When mertoran leaf is dried, treated, and chewed, it provides appropriate
alchemical treatment for personality disorders, and substance abuse
disorders.`}</p>
      <i>Initial Effect:</i>
1 point of <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#ability-damaged">Dexterity damage</a>.
      <i>Secondary Effect:</i>
The user becomes more confident, gaining a +2 alchemical bonus to Charisma
for 1 hour.
      <i>Side Effect:</i>
While the drug is in effect and for 12 hours thereafter, mertoran leaf
relieves the most severe symptoms of the listed disorders.
      <i>Overdose:</i>
n/a.</p>
    <p><b><a id="zixalix"></a>Zixalix</b>
      <p>{`A potent combination of many rare herbs and alchemical substances, zixalix
provides appropriate alchemical treatment for somatoform disorders,
psychotic disorders (including schizophrenia), and psychosexual disorders.`}</p>
      <i>Initial Effect:</i>
1d4 points of <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#ability-damaged">Intelligence damage</a>.
      <i>Secondary Effect:</i>
Zixalix staves off the most severe symptoms of the indicated disorders.
      <i>Side Effect:</i>
n/a.
      <i>Overdose:</i>
If more than three doses are taken in a 24-hour period, the imbiber takes
1d4 points of <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#ability-damaged">Constitution damage</a>.</p>
    <h6><a id="drug-addiction"></a>
Drug Addiction
    </h6>
Drug addiction functions much like <a style={{
      "color": "#579eb6"
    }} href="abilitiesAndConditions.html#disease">diseases</a>. The
characteristics of certain forms of addiction are summarized on the <a style={{
      "color": "#579eb6"
    }} href="#table-addictions">table
below</a>. Upon initial exposure (any time a character imbibes or applies a
drug with an addiction rating), the character must succeed on a Fortitude
save or become addicted. Instead of having an incubation period as a
disease does, a drug has a satiation period, which is the length of time a
single dose remains effective in a character�s system. Addiction, if not
satisfied by further doses of the drug, proceeds like a disease � the
character takes <a style={{
      "color": "#579eb6"
    }} href="abilitiesAndConditions.html#ability-damaged">ability damage</a> each day unless he succeeds on a Fortitude
save.
    <b><a id="table-addictions"></a>Table: Addictions</b>
    <table cellSpacing="0" cellPadding="0" style={{
      "width": "50%"
    }}>
      <tr>
        <th align="left">
Addiction
          <p>{`Rating`}</p>
        </th>
        <th>
Fort
          <p>{`DC`}</p>
        </th>
        <th align="left">
Satiation
          <p>{`Period`}</p>
        </th>
        <th align="left">
Damage
        </th>
      </tr>
      <tr className="odd-row">
        <td>
Negligible
        </td>
        <td align="center">
4
        </td>
        <td>
1 day
        </td>
        <td>
1d3-2 Dex (can be 0)
        </td>
      </tr>
      <tr>
        <td>
Low
        </td>
        <td align="center">
6
        </td>
        <td>
10 days
        </td>
        <td>
1d3 Dex
        </td>
      </tr>
      <tr className="odd-row">
        <td>
Medium
        </td>
        <td align="center">
10
        </td>
        <td>
5 days
        </td>
        <td>
1d4 Dex, 1d4 Wis
        </td>
      </tr>
      <tr>
        <td>
High
        </td>
        <td align="center">
14
        </td>
        <td>
1 day
        </td>
        <td>
1d6 Dex, 1d6 Wis, 1d6 Con
        </td>
      </tr>
      <tr className="odd-row">
        <td>
Extreme
        </td>
        <td align="center">
25
        </td>
        <td>
1 day
        </td>
        <td>
1d8 Dex, 1d8 Wis, 1d6 Con, 1d6 Str
        </td>
      </tr>
    </table>
    <p><b><a id="addiction-rating"></a>Addiction Rating:</b>
Each drug is rated according to its addictive potential, from lowest
(negligible) to highest (extreme). Sometimes, an individual�s long-term
addiction raises a drug�s addiction rating for that individual. Drugs with
a negligible rating are not subject to this change. Stronger drugs
increase their addiction rating by one step for every two full months a
character remains addicted to the drug. A character who recovers from an
addiction and later becomes addicted again to the same drug does so at the
addiction rating the drug had just prior to his earlier recovery.
    </p>
    <p><b><a id="satiation"></a>Satiation:</b>
Each time a user takes a drug to which he is addicted, he is satiated and
staves off withdrawal symptoms for the period of time indicated on the
table. Whenever the satiation period expires before the user takes another
dose, the DC of the Fortitude save to resist damage (see below) increases
by 5. The dose that causes a character to becomes addicted counts for the
purpose of tracking the satiation period.
    </p>
    <p><b><a id="drug-damage"></a>Damage:</b>
An addicted user who is not satiated takes the indicated amount of ability
damage each day unless the character succeeds on a Fortitude saving throw.
    </p>
    <p><b><a id="recovery"></a>Recovery:</b>
If a character makes two successful saving throws in a row, he has fought
off his addiction and recovered, and takes no more damage from withdrawal
symptoms.
    </p>
    <p>
A <a style={{
        "color": "#579eb6"
      }} href="spellsPtoR.html#lesser-restoration">lesser restoration</a> or <a style={{
        "color": "#579eb6"
      }} href="spellsPtoR.html#restoration">restoration</a> spell might negate some or all of the
ability damage caused by an addiction, but the next day the victim may
take more ability damage if he continues to fail his Fortitude saves.
      <a style={{
        "color": "#579eb6"
      }} href="spellsPtoR.html#remove-disease">Remove disease</a> immediately causes a user to recover from an addiction, but
it does not heal ability damage. <a style={{
        "color": "#579eb6"
      }} href="spellsPtoR.html#greater-restoration">Greater restoration</a> or heal causes
recovery and restores all ability damage from the addiction.
    </p>
    <h5><a id="treatment-of-insanity"></a>
TREATMENT OF INSANITY
    </h5>
    <a style={{
      "color": "#579eb6"
    }} href="#teporary-insanity">Temporary insanity</a> ends so quickly that schedules of treatment are
essentially pointless; it runs its course soon enough that one merely need
protect a deranged character from further upset or harm. On the other
hand, treatment of <a style={{
      "color": "#579eb6"
    }} href="#permanent-insanity">permanent insanity</a> has no real meaning. By definition,
a permanently insane character never recovers, no matter how good the
therapist or the facility. Thus, <a style={{
      "color": "#579eb6"
    }} href="#indefinite-insanity">indefinite insanity</a> is the only form of
mental illness that might be addressed by intervention and treatment.
    <p>
After 1d6 months, if undisturbed by further trauma and with the agreement
of the Game Master, an indefinitely insane character finds enough mental
balance to reenter the world. Three kinds of nonmagical care may help the
character regain Sanity points during this recovery period. When choosing
among them, the GM and player should consider the character�s resources,
her friends and relatives, and how wisely she has behaved in the past. In
most campaigns, the magical treatments described above (see <a style={{
        "color": "#579eb6"
      }} href="#restoring-sanity-with-magic">Restoring
Sanity with Magic</a>) allow the character to reenter play after a shorter
time or with less expense.
    </p>
    <h6><a id="private-care"></a>
Private Care
    </h6>
The best care available is at home or in some friendly place (perhaps a
small church or the home of a wealthy friend) where nursing can be tender,
considerate, and undistracted by the needs of competing patients.
    <p>
If mental healing or alchemical medications are available, roll d% for
each game month that one or the other is used. A result of 01-95 is a
success: Add 1d3 Sanity points for either mental therapy or alchemical
medications, whichever is used (a character cannot benefit from both in
the same month). On a result of 96-100, the healer fumbles the diagnosis
or the character rejects the alchemical treatments. She loses 1d6 Sanity
points, and no progress is made that month.
    </p>
    <h6><a id="institutionalization"></a>
Institutionalization
    </h6>
The next best alternative to private care is commitment to a good insane
asylum, but these are extremely rare in most d20 campaigns, if they are
present at all. GMs are free to rule that institutionalization is simply
not available.
    <p>
In those campaigns that include such institutions (usually located within
the bounds of a temple devoted to a deity of healing), asylums may be said
to have an advantage over home care in that they are relatively cheap or
even a free service provided by a government or a powerful church. These
institutions are of uneven quality, however, and some may be potentially
harmful. Some are creative places of experiment and magic-assisted
therapy, while others offer mere confinement. In any setting, concentrated
and nourishing treatment by strangers is rare.
    </p>
    <p>
Therapy using the <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#heal">Heal</a> skill is usually the only treatment available, but
in most cases, primitive institutions offer no treatment at all. Sometimes
an institution can convey an uncaring sense that undermines the useful
effects of alchemical medications, leaving the character with a sense of
anger and loss. He is likely to be distrustful of the organization and its
motives. Escape attempts are common by inmates, even in the most
enlightened fantasy settings.
    </p>
    <p>
Roll d% for each game month a character is in the care of an institution.
A result of 01-95 is a success; add 1d3 Sanity points if therapy with the
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#heal">Heal</a> skill was available, or 1 Sanity point if no treatment was present.
On a result of 96-100, the character rebels against the environment. He
loses 1d6 Sanity points, and no progress can be made that month.
    </p>
    <h6><a id="wandering-and-homeless"></a>
Wandering and Homeless
    </h6>
If no care is available, an insane character may become a wandering
derelict struggling for survival. Such a wanderer gains no Sanity points
unless he is able to join a group of the homeless and find at least one
friend among them. To find a friend after joining such a group, the
character can make a DC 15 Charisma check once per month. If a friend
appears, the character recovers 1 Sanity point per game month thereafter.
    <p>
For each game month during which an insane character lives as a derelict,
roll d%. On a result of 01-95, the character survives. On a result of
96-100, the character dies as the result of disease, exposure, or
violence.
    </p>
    <h5><a id="mental-disorders"></a>
MENTAL DISORDERS
    </h5>
The GM should choose how characters in the campaign world think and
therefore talk about insanity before play begins. In many fantasy games,
the term �insane� serves as an all-encompassing term that represents
everything an inhabitant knows or understands about the full spectrum of
mental disorders. In others, different forms of insanity may be identified
for what they are.
    <p>
This section offers descriptions of many specific mental disorders. Where
appropriate, suggested modifiers to characters� attack rolls, saves, and
checks are also given.
    </p>
    <h6><a id="anxiety-disorders"></a>
Anxiety Disorders
    </h6>
Even a seasoned adventurer feels anxious before braving a dragon�s cave,
and the farmers in the village might worry that their crops will not
survive until harvest. These fears are a normal, natural part of living in
a danger-filled environment such as a d20 campaign setting, but in some
cases these anxieties overwhelm an individual, causing inactivity,
distress, and even severe behavioral problems. When fear and anxiety
overwhelm a character for a prolonged period of time, the character
suffers from an anxiety disorder. The most common forms of anxiety
disorders are described below.
    <p><b><a id="generalized-anxiety-disorder"></a>Generalized Anxiety Disorder:</b>
The character suffers from a variety of physical and emotional symptoms
that can be grouped into certain categories.
    </p>
    <p><i><a id="motor-tension"></a>Motor Tension:</i>
Jitteriness, aches, twitches, restlessness, easily startled, easily
      <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#fatigued">fatigued</a>, and so on. All attack rolls, Fortitude and Reflex saves, and all
checks involving Strength, Dexterity, or Constitution take a -2 penalty.
    </p>
    <p><i><a id="autonomic-hyperactivity"></a>Autonomic Hyperactivity:</i>
Sweating, racing heart, dizziness, clammy hands, flushed or pallid face,
rapid pulse and respiration even when at rest, and so on. All attack
rolls, saves, and checks take a -2 penalty.
    </p>
    <p><i><a id="expectations-of-doom"></a>Expectations of Doom</i>
Anxieties, worries, fears, and especially anticipations of misfortune. All
attack rolls, saves, and checks take a -2 morale penalty.
    </p>
    <p><i><a id="vigilance"></a>Vigilance:</i>
Distraction, inability to focus, insomnia, irritability, impatience. All
Will saves and checks involving Intelligence, Wisdom, or Charisma take a
-4 morale penalty.
    </p>
    <p><b><a id="panic-disorder-panic-attack"></a>Panic Disorder (Panic Attack):</b>
This illness is marked by a discrete period of fear in which symptoms
develop rapidly. Within minutes palpitation, sweating, trembling, and
difficulty in breathing develop, strong enough that the victim fears
immediate death or insanity. Burdened with the recurrence of these
episodes, she fears their return. This reaction often leads to <a style={{
        "color": "#579eb6"
      }} href="#agoraphobia">agoraphobia</a>
(see below).
    </p>
    <p><b><a id="agoraphobia-fear-of-open-places"></a><a id="agoraphobia"></a>Agoraphobia (Fear of Open Places):</b>
The character becomes very nervous outside familiar surroundings and must
make a DC 15 Will save in order to leave home or engage socially. May be
linked to <a style={{
        "color": "#579eb6"
      }} href="#panic-disorder-panic-attack">panic disorder</a> (see above) or to a related <a style={{
        "color": "#579eb6"
      }} href="#phobia">phobia</a> (see below),
such as uranophobia (fear of the sky), baraphobia (fear of loss of
gravity), or xenophobia (fear of strangers).
    </p>
    <p><b><a id="obsessive-compulsive-disorder"></a>Obsessive-Compulsive Disorder:</b>
This illness manifests in one of two main forms, obsessive thoughts or
compulsive actions; some characters exhibit both.
    </p>
    <p><i><a id="obsessions"></a>Obsessions:</i>
The character cannot help thinking about an idea, image, or impulse
incessantly, often involving violence and self-doubt. These ideas are
frequently repugnant to the character, but they are so strong that during
times of stress she may be unable to concentrate on anything else, even if
doing so is necessary for her survival. Obsessive impulses can be very
dangerous when combined with auditory hallucinations, since the �voices�
may urge the character to take some dangerous or hostile course of action.
    </p>
    <p><i><a id="compulsions"></a>Compulsions:</i>
The character insists on performing ritual actions, such as touching a
doorway at left, right, and top before passing through it. Though she may
agree that the actions are senseless, the need to perform them is
overpowering and may last for 1d10 rounds. Even in times of great stress,
the character may ignore her survival in order to perform the actions.
    </p>
    <p><b><a id="post-traumatic-stress-disorder"></a>Post-Traumatic Stress Disorder:</b>
After a traumatic event, perhaps even years later, the character begins to
relive the trauma through persistent thoughts, dreams, and flashbacks.
Correspondingly, the character loses interest in daily activities. She may
return to normal once the memories have been thoroughly explored and
understood, but that process may take years.
    </p>
    <p><b><a id="phobia-or-mania"></a>Phobia or Mania:</b>
A character afflicted by a phobia or a mania persistently fears a
particular object or situation. She realizes that the fear is excessive
and irrational, but the fear is disturbing enough that she avoids the
stimulus.
    </p>
    <p><i><a id="phobia"></a>Phobia:</i>
      <a href="http://www.wizards.com/dnd/images/UnA_Gallery/79181.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a>A DC 15 Will check is required for a character to be able to force herself
into (or remain within) the presence of the object of her phobia, and even
then the character takes a -2 morale penalty as long as the object of fear
remains. In severe cases, the object of the phobia is imagined to be
omnipresent, perhaps hidden � thus, someone with severe acrophobia (fear of
heights) might be frightened when in an enclosed room on the upper story
of a building, even if there were no window or other way to see how high
up the room was. As many phobias exist as one cares to notice or name � the
lists provided below cover merely some of the more common phobias that
might affect d20 characters.
    </p>
    <p><i><a id="mania"></a>Mania:</i>
Manias are rarer than phobias. A character affected by a mania is
inordinately fond of a particular stimulus and takes great pains to be
with it or near it. When the character�s sexuality is involved, the mania
may be termed a fetish. Thus, teratophobia would be an inordinate fear of
monsters, while teratophilia would be an unhealthy (possibly sexual)
attraction to them. See the following lists of phobias for ideas on what
sorts of disorders could manifest as manias.
    </p>
    <p><b><a id="real-world-phobias"></a>Real-World Phobias:</b>
The following list provides examples of phobias from the real world that
lend themselves to inclusion in a d20 campaign.
    </p>
    <p>
Certain real-world phobias can easily be broadened to include monstrous
creatures and specific magic effects in a fantasy environment. For
example, ophidiophobia (fear of snakes) could be extended to include
medusae and other snakelike creatures, or ichthyophobia (fear of fish)
could be extended to include aquatic creatures with fishlike qualities,
such as the locathah and the sahuagin.
    </p>
    <table cellSpacing="0" cellPadding="0" style={{
      "width": "100%"
    }}>
      <tr>
        <td valign="top" style={{
          "width": "32%"
        }}>
          <a id="table-real-world-phobias"><b>Table: Real World Phobias</b></a>
          <table cellSpacing="0" cellPadding="0" style={{
            "width": "100%"
          }}>
            <tr>
              <th>
Phobia
              </th>
              <th>
Fear of�
              </th>
            </tr>
            <tr className="odd-row">
              <td>
Acrophobia
              </td>
              <td>
heights (formerly known as vertigo)
              </td>
            </tr>
            <tr>
              <td>
Aerophobia
              </td>
              <td>
wind
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Agoraphobia
              </td>
              <td>
open places
              </td>
            </tr>
            <tr>
              <td>
Ailurophobia
              </td>
              <td>
cats
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Androphobia
              </td>
              <td>
men (males)
              </td>
            </tr>
            <tr>
              <td>
Astrophobia
              </td>
              <td>
stars
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Autophobia
              </td>
              <td>
being alone
              </td>
            </tr>
            <tr>
              <td>
Bacteriophobia
              </td>
              <td>
bacteria (�germs�)
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Ballistophobia
              </td>
              <td>
bullets
              </td>
            </tr>
            <tr>
              <td>
Bathophobia
              </td>
              <td>
deep submerged places
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Bibliophobia
              </td>
              <td>
books
              </td>
            </tr>
            <tr>
              <td>
Blennophobia
              </td>
              <td>
slime
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Brontophobia
              </td>
              <td>
thunder
              </td>
            </tr>
            <tr>
              <td>
Cenophobia
              </td>
              <td>
empty rooms
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Chionophobia
              </td>
              <td>
snow
              </td>
            </tr>
            <tr>
              <td>
Claustrophobia
              </td>
              <td>
enclosed spaces
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Demophobia
              </td>
              <td>
crowds
              </td>
            </tr>
            <tr>
              <td>
Dendrophobia
              </td>
              <td>
trees
              </td>
            </tr>
          </table>
        </td>
        <td style={{
          "width": "2%"
        }}></td>
        <td valign="top" style={{
          "width": "32%"
        }}>
          <p>{`Continued...`}</p>
          <table cellSpacing="0" cellPadding="0" style={{
            "width": "100%"
          }}>
            <tr className="odd-row">
              <td>
Entomophobia
              </td>
              <td>
insects
              </td>
            </tr>
            <tr>
              <td>
Equinophobia
              </td>
              <td>
horses
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Gephyrdrophobia
              </td>
              <td>
crossing bridges
              </td>
            </tr>
            <tr>
              <td>
Gynephobia
              </td>
              <td>
women (females)
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Hamartophobia
              </td>
              <td>
sinning or making an error
              </td>
            </tr>
            <tr>
              <td>
Haphephobia
              </td>
              <td>
being touched
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Heliophobia
              </td>
              <td>
sunlight or the sun
              </td>
            </tr>
            <tr>
              <td>
Hematophobia
              </td>
              <td>
blood or bleeding
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Hydrophobia
              </td>
              <td>
water
              </td>
            </tr>
            <tr>
              <td>
Hypnophobia
              </td>
              <td>
sleep
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Iatrophobia
              </td>
              <td>
doctors (healers)
              </td>
            </tr>
            <tr>
              <td>
Ichthyophobia
              </td>
              <td>
fish
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Maniaphobia
              </td>
              <td>
going insane
              </td>
            </tr>
            <tr>
              <td>
Monophobia
              </td>
              <td>
being alone
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Musophobia
              </td>
              <td>
mice (and rats)
              </td>
            </tr>
            <tr>
              <td>
Necrophobia
              </td>
              <td>
dead things
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Nyctophobia
              </td>
              <td>
night or nightfall
              </td>
            </tr>
            <tr>
              <td>
Odontophobia
              </td>
              <td>
teeth
              </td>
            </tr>
          </table>
        </td>
        <td style={{
          "width": "2%"
        }}></td>
        <td valign="top" style={{
          "width": "32%"
        }}>
          <p>{`Continued...`}</p>
          <table cellSpacing="0" cellPadding="0" style={{
            "width": "100%"
          }}>
            <tr className="odd-row">
              <td>
Onomatophobia
              </td>
              <td>
a certain name, word, or phrase
              </td>
            </tr>
            <tr>
              <td>
Ophidiophobia
              </td>
              <td>
snakes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Ornithophobia
              </td>
              <td>
birds
              </td>
            </tr>
            <tr>
              <td>
Pediphobia
              </td>
              <td>
children
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Phagophobia
              </td>
              <td>
eating
              </td>
            </tr>
            <tr>
              <td>
Phonophobia
              </td>
              <td>
noise, including one�s own voice
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Pyrophobia
              </td>
              <td>
fire
              </td>
            </tr>
            <tr>
              <td>
Scotophobia
              </td>
              <td>
darkness
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Spectrophobia
              </td>
              <td>
mirrors
              </td>
            </tr>
            <tr>
              <td>
Taphephobia
              </td>
              <td>
being buried alive
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Teratophobia
              </td>
              <td>
monsters
              </td>
            </tr>
            <tr>
              <td>
Thalassophobia
              </td>
              <td>
the sea
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Tomophobia
              </td>
              <td>
surgery
              </td>
            </tr>
            <tr>
              <td>
Uranophobia
              </td>
              <td>
the heavens (�the horrible gaping sky!�)
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Vermiphobia
              </td>
              <td>
worms
              </td>
            </tr>
            <tr>
              <td>
Xenophobia
              </td>
              <td>
foreigners or strangers
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Zoophobia
              </td>
              <td>
animals
              </td>
            </tr>
          </table>
        </td></tr></table>
    <p><b><a id="fantastic-phobias"></a>Fantastic Phobias:</b>
With some imagination and a little knowledge about how �phobia� terms are
formed (most come from ancient Greek words), it�s possible to come up with
a list of phobias tailored to a fantasy setting. The following list is
provided as a starting point.
    </p>
    <a id="table-fantastic-phobias"><b>Table: Fantastic Phobias</b></a>
    <table cellSpacing="0" cellPadding="0" style={{
      "width": "40%"
    }}>
      <tr>
        <th>
Phobia
        </th>
        <th>
Fear of�
        </th>
      </tr>
      <tr className="odd-row">
        <td>
Arcuophobia
        </td>
        <td>
bows
        </td>
      </tr>
      <tr>
        <td>
Aurophobia
        </td>
        <td>
gold
        </td>
      </tr>
      <tr className="odd-row">
        <td>
Aberraphobia
        </td>
        <td>
aberrations and creatures with tentacles
        </td>
      </tr>
      <tr>
        <td>
Bogyphobia
        </td>
        <td>
demons and goblins
        </td>
      </tr>
      <tr className="odd-row">
        <td>
Demonophobia
        </td>
        <td>
demons
        </td>
      </tr>
      <tr>
        <td>
Dracophobia
        </td>
        <td>
dragons
        </td>
      </tr>
      <tr className="odd-row">
        <td>
Confodiophobia
        </td>
        <td>
being stabbed
        </td>
      </tr>
      <tr>
        <td>
Faephobia
        </td>
        <td>
fey
        </td>
      </tr>
      <tr className="odd-row">
        <td>
Gigaphobia
        </td>
        <td>
giants and Large or larger creatures
        </td>
      </tr>
      <tr>
        <td>
Hadephobia
        </td>
        <td>
hell
        </td>
      </tr>
      <tr className="odd-row">
        <td>
Hagiophobia
        </td>
        <td>
saints and holy relics
        </td>
      </tr>
      <tr>
        <td>
Hierophobia
        </td>
        <td>
priests and sacred items
        </td>
      </tr>
      <tr className="odd-row">
        <td>
Incantophobia
        </td>
        <td>
enchantment and mind control
        </td>
      </tr>
      <tr>
        <td>
Iophobia
        </td>
        <td>
poison
        </td>
      </tr>
      <tr className="odd-row">
        <td>
Manaphobia
        </td>
        <td>
magic
        </td>
      </tr>
      <tr>
        <td>
Materiophobia
        </td>
        <td>
constructs
        </td>
      </tr>
      <tr className="odd-row">
        <td>
Myxophobia
        </td>
        <td>
oozes
        </td>
      </tr>
      <tr>
        <td>
Naturaphobia
        </td>
        <td>
nature and druids
        </td>
      </tr>
      <tr className="odd-row">
        <td>
Planarphobia
        </td>
        <td>
outsiders and extraplanar creatures
        </td>
      </tr>
      <tr>
        <td>
Plantaphobia
        </td>
        <td>
plants and plant creatures
        </td>
      </tr>
      <tr className="odd-row">
        <td>
Phantasmaphobia
        </td>
        <td>
specters or ghosts
        </td>
      </tr>
      <tr>
        <td>
Pneumatophobia
        </td>
        <td>
incorporeal creatures
        </td>
      </tr>
      <tr className="odd-row">
        <td>
Uranophobia
        </td>
        <td>
heaven (esp. divine magic)
        </td>
      </tr>
    </table>
    <h6><a id="dissociative-disorders"></a>
Dissociative Disorders
    </h6>
Individuals suffering from dissociative disorders cannot maintain a
complete awareness of themselves, their surroundings, or time. The
disorder often involves some great previous trauma that is too terrible to
remember. Characters who have gone insane from an encounter with powerful
monsters often suffer from some form of dissociative disorder.
    <p><b><a id="dissociative-amnesia-psychogenic-amnesia"></a>Dissociative Amnesia (Psychogenic Amnesia):</b>
This is the inability to recall important personal information, brought on
by a desire to avoid unpleasant memories. The character must make a DC 20
Will save to recall such details or the cause of the amnesia. Since the
horror of evil creatures and disturbing truths is the probable cause of
this amnesia, as an optional rule the GM may choose to reset the
character�s <a style={{
        "color": "#579eb6"
      }} href="#knowledge-forbidden-lore">Knowledge (forbidden lore)</a> modifier to +0 and her maximum
Sanity to 99 while this disorder holds sway: The horror returns only when
the character�s memories do.
    </p>
    <p><b><a id="dissociative-fuge"></a>Dissociative Fugue:</b>
The character flees from home or work and cannot recall her past. Once the
flight halts, the character may assume an entirely new identity.
    </p>
    <p><b><a id="dissosiative-identity-disorder-multiple-personality-disorder"></a>Dissociative Identity Disorder (Multiple Personality Disorder):</b>
The character appears to harbor more than one personality, each of which
is dominant at times and has its own distinct behavior, name, and even
gender. The player needs to keep track of the character�s different
personalities. (Each one has the same ability scores and game statistics,
but different goals, outlooks, and attitudes.)
    </p>
    <h6><a id="eating-disorders"></a>
Eating Disorders
    </h6>
These disorders can be incredibly debilitating and even lead to
starvation. They are conditions that may continue for many years,
sometimes continually endangering the patient.
    <p><b><a id="anorexia-nervosa"></a>Anorexia Nervosa:</b>
The character has an overpowering fear of becoming fat and consequently
loses weight, as well as taking <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#ability-damaged">Constitution damage</a> (at a rate of 1d8
points per week). Even when she is no more than skin and bones, the
character continues to see herself as overweight. Without intervention,
she may literally starve herself to death.
    </p>
    <p><b><a id="bulimia-nervosa"></a>Bulimia Nervosa:</b>
The character frequently eats large amounts of food during secret binges.
An eating episode may continue until abdominal distress or self-induced
vomiting occurs. Feelings of depression and guilt frequently follow such
episodes.
    </p>
    <h6><a id="impulse-control-disorders"></a>
Impulse Control Disorders
    </h6>
These disorders include compulsive gambling, pathological lying,
kleptomania (compulsive stealing), and pyromania (the compulsion to set
fires).
    <p><b><a id="intermittent-explosive-disorder"></a>Intermittent Explosive Disorder:</b>
The character is recognizably impulsive and aggressive, and at times gives
way to uncontrollable rages that result in assault or destruction of
property.
    </p>
    <h6><a id="mood-disorders"></a>
Mood Disorders
    </h6>
These disorders affect the victim�s attitude and outlook. Mild mood
disorders can be almost impossible to detect without prolonged contact
with an individual, but severe disorders usually have noticeable symptoms.
    <p><b><a id="depression"></a>Depression:</b>
Symptoms of this illness include changes in appetite, weight gain or loss,
too much or too little sleep, persistent feeling of tiredness or
sluggishness, and feelings of worthlessness or guilt, leading in severe
cases to hallucinations, delusions, stupor, or thoughts of suicide. All
attack rolls, saves, and checks take a -4 morale penalty. A predisposition
to use alcohol or other mood-altering substances in an attempt at
self-medication exists. A character suffering from severe chronic
depression may give up virtually all effort from feelings of
hopelessness � for example, deciding not to get out of bed for two years.
    </p>
    <p><b><a id="mood-disorder-mania"></a>Mania:</b>
The character has a fairly constant euphoric or possibly irritable mood.
Symptoms include a general increase in activity, talkativeness, increased
self-esteem to the point of delusion, decreased need for sleep, being
easily distracted, willingness for dangerous or imprudent activities such
as reckless driving, delusions, hallucinations, and bizarre behavior. All
attack rolls, saves, and checks take a -4 morale penalty A predisposition
to use alcohol or other substances in an attempt at self-medication
exists.
    </p>
    <p><b><a id="bipolar-mood-disorder"></a>Bipolar Mood Disorder:</b>
The character oscillates between mood states, sometimes staying in one
mood for weeks at a time, sometimes rapidly switching from one to another.
Also known as manic depressive.
    </p>
    <h6><a id="personality-disorders"></a>
Personality Disorders
    </h6>
These long-term disorders have almost constant effects on a character�s
behavior, making it difficult for him to interact with others and often
making him unpleasant to be around as well. This is an important point to
keep in mind when roleplaying � few players want to spend time with another
player character suffering from a personality disorder.
    <p>
In game terms, the character takes a -4 penalty on all Charisma-based
checks. In addition, the attitudes of NPCs the character encounters are
shifted in a negative direction. When determining NPC atitudes, the
player must make a Charisma check for the character. On a successful
check, the attitude of the NPC in question shifts one step toward hostile;
on a failed check, the attitude of the NPC in question shifts two steps
toward hostile.
    </p>
    <p>
Personality disorders are classified in the following categories.
    </p>
    <p><b><a id="antisocial"></a>Antisocial:</b>
Short-sighted and reckless behavior, habitual liar, confrontational, fails
to meet obligations (job, bills, relationships), disregards rights and
feelings of others.
    </p>
    <p><b><a id="avoidant"></a>Avoidant:</b>
Oversensitive to rejection, low self-esteem, socially withdrawn.
    </p>
    <p><b><a id="borderline"></a>Borderline:</b>
Rapid mood shifts, impulsive, unable to control temper, chronic boredom.
    </p>
    <p><b><a id="compulsive"></a>Compulsive:</b>
Perfectionist, authoritarian, indecisive from fear of making mistakes,
difficulty expressing emotions.
    </p>
    <p><b><a id="dependent"></a>Dependent:</b>
Lacks self-confidence; seeks another to look up to, follow, and
subordinate herself to (�codependent�).
    </p>
    <p><b><a id="histrionic"></a>Histrionic:</b>
Overly dramatic, craves attention and excitement, overreacts, displays
temper tantrums, may threaten suicide if thwarted.
    </p>
    <p><b><a id="narcissistic"></a>Narcissistic:</b>
Exaggerated sense of self-importance, craves attention and admiration,
considers others� rights and feelings as of lesser importance.
    </p>
    <p><b><a id="passive-aggressive"></a>Passive-Aggressive:</b>
Procrastinator, stubborn, intentionally forgetful, deliberately
inefficient. Sabotages own performance on a regular basis.
    </p>
    <p><b><a id="paranoid"></a>Paranoid:</b>
Jealous, easily offended, suspicious, humorless, secretive, vigilant;
exaggerates magnitude of offenses against oneself, refuses to accept
blame.
    </p>
    <p><b><a id="schizoid"></a>Schizoid:</b>
Emotionally cold, aloof, has few friends; indifferent to praise or
criticism.
    </p>
    <p>
GMs should realize that, while these traits may work for an interesting
NPC from whom the players must extract information or a favor, their
antisocial nature makes them ill-suited for members of an adventuring
party.
    </p>
    <h6><a id="psychosexual-disorders"></a>
Psychosexual Disorders
    </h6>
Recognizable disorders of this type include transsexualism (a belief that
one is actually a member of the opposite sex), impaired sexual desire or
function, nymphomania and satyriasis (inordinate and uncontrollable sexual
appetite in women and men, respectively), and paraphilia (requirement of
an abnormal sexual stimulus, such as sadism, masochism, necrophilia,
pedophilia, exhibitionism, voyeurism, fetishism, or bestiality).
    <p>
Most of these disorders could make players of the afflicted characters
uncomfortable and thus are not appropriate for most roleplaying groups,
although they can make for striking (if unpleasant) NPCs.
    </p>
    <h6><a id="psychospecies-disorders"></a>
Psychospecies Disorders
    </h6>
These disorders are specific to fantasy environments and involve the
victim of one believing that she is a different type of creature. A victim
might believe that she is a construct (and therefore immune to critical
hits) or any other creature type that she has encountered. When a victim
has a psychospecies disorder associated with a creature that has specific
weaknesses (for example, a human thinking he�s a <a style={{
      "color": "#579eb6"
    }} href="monstersTtoZ.html#vampire">vampire</a>), then the
victim�s behavior changes become more noticeable (such as a fear of holy
symbols and sunlight).
    <h6><a id="schizophrenia-and-other-psychotic-disorders"></a>
Schizophrenia and Other Psychotic Disorders
    </h6>
A psychotic character experiences a break with reality. Symptoms can
include delusions, hallucinations, and cognitive impairment. In general,
only alchemical substances or magic can treat these kinds of disorders.
Note, however, that many psychotic characters suffer from the delusion
that nothing is wrong with them, and hence they feel no need to take their
medication.
    <p><b><a id="schizophrenia-schizophreniform-disorder-dementia-praecox"></a>Schizophrenia (Schizophreniform Disorder, Dementia Praecox):</b>
A schizophrenic character�s attention span and ability to concentration
are greatly diminished; to reflect this, use only one-half of the
character�s normal skill modifier on any skill check requiring
attentiveness (such as <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#disable-device">Disable Device</a>, <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#spot">Spot</a>, <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#search">Search</a>, <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#open-lock">Open Lock</a>, and of
course <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#concentration">Concentration</a>). Symptoms include bizarre delusions, paranoia,
auditory hallucinations (�hearing voices�), incoherent speech, emotional
detachment, social withdrawal, bizarre behavior, and a lack of the sense
of self.
    </p>
    <p>
A schizophrenic character may fit into one of the following categories.
    </p>
    <p><i><a id="undifferentiated-schizophrenia"></a>Undifferentiated:</i>
Impaired cognitive function, emotional detachment.
    </p>
    <p><i><a id="disorganized-schizophrenia"></a>Disorganized:</i>
Inappropriate behavior, shallow emotional responses, delusions,
hallucinations.
    </p>
    <p><i><a id="catatonic-schizophrenia"></a>Catatonic:</i>
Mutism (loss of ability to talk), extreme compliance, absence of all
voluntary movements, complete immobility (�statuism�).
    </p>
    <p><i><a id="paranoid-schizophrenia"></a>Paranoid:</i>
Delusions of persecution, illogical thinking, hallucinations.
    </p>
    <p>
Symptoms from more than one type can occur in the same individual, along
with mood disorders (see above). For example, catatonic schizophrenics
sometimes have manic episodes of extreme activity alternating with periods
of complete withdrawal. Schizophrenia brought on by sudden stress is
called acute schizophrenia; characters who go insane and babble of vast
global conspiracies usually are diagnosed as suffering from �acute
paranoid schizophrenia.�
    </p>
    <p><b><a id="other-psychotic-disorders"></a>Other Psychotic Disorders:</b>
By some definitions, all severe mental illnesses are classified as
psychoses, including mood disorders, dementia, and anxiety disorders. This
section deals with some of the interesting behavioral syndromes that may
turn up in your game.
    </p>
    <p><i><a id="amok"></a>Amok:</i>
�Running amok,� an outburst of violence and aggressive or homicidal
behavior directed at people and property. Amnesia, return to
consciousness, and exhaustion occur following the episode. During a
killing spree, the character utilizes whatever weapons are on hand.
    </p>
    <p><i><a id="boufee-detirant"></a>Boufee Detirant:</i>
Sudden outburst of aggressive, agitated behavior and marked confusion,
sometimes accompanied by visual and auditory hallucinations or paranoia.
    </p>
    <p><i><a id="brain-fag"></a>Brain Fag:</i>
Impaired concentration and feelings of fatigue, pains in the neck and
head, a sense that worms are crawling inside one�s head.
    </p>
    <p><i><a id="ghost-sickness"></a>Ghost Sickness:</i>
Weakness, loss of appetite, feelings of suffocation, nightmares, and a
pervasive feeling of terror, attributed as a sending from witches or
malign otherworldly powers.
    </p>
    <p><i><a id="piblokto"></a>Piblokto:</i>
�Arctic madness,� wherein the afflicted rips off clothing and runs howling
like an animal through the snow.
    </p>
    <p><i><a id="susto"></a>Susto:</i>
A variety of somatic and psychological symptoms attributed to a traumatic
incident so frightening that it dislodged the victim�s spirit from her
body.
    </p>
    <p><i><a id="taijin-kyofusho"></a>Taijin Kyofusho:</i>
�Face-to-face� phobia, an intense anxiety when in the presence of other
people; fearfulness that one�s appearance, odor, or behavior is offensive.
    </p>
    <p><i><a id="voodoo-death"></a>Voodoo Death:</i>
Belief that a hex or curse can bring about misfortune, disability, and
death through some spiritual mechanism. Often the victim self-fulfills the
hexer�s prophecy by refusing to eat and drink, resulting in dehydration
and starvation.
    </p>
    <p><i><a id="wacinko"></a>Wacinko:</i>
Anger, withdrawal, mutism, and immobility, leading to illness and suicide.
    </p>
    <p><i><a id="wendigo-syndrome"></a>Wendigo Syndrome:</i>
The afflicted believes she is a personification of the Wendigo, a
cannibalistic creature with an icy heart.
    </p>
    <p><b><a id="shared-paranoid-disorder-shared-delusional-disorder-folie-a-deux"></a>Shared Paranoid Disorder (Shared Delusional Disorder, <i>Folie a Deux</i>)</b>
The character takes on the delusional system of another paranoid
individual from being in close contact with that person.
    </p>
    <h6><a id="sleep-disorders"></a>
Sleep Disorders
    </h6>
These disorders include insomnia (character has difficulty falling asleep
or staying asleep) and narcolepsy (character frequently falls asleep,
almost anywhere and at inappropriate times). Characters performing
demanding tasks such as engaging in combat or casting a spell may, when
stressed, need to make DC 15 <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#concentration">Concentration</a> checks to stay awake and not
put themselves in a dangerous situation.
    <p><b><a id="night-terrors"></a>Night Terrors:</b>
A sleeping character wakes after a few hours of sleep, usually screaming
in terror. Pulse and breathing are rapid, pupils are dilated, and hair
stands on end. The character is confused and hard to calm down. Night
terrors are similar to ordinary nightmares, but much more intense and
disruptive.
    </p>
    <p><b><a id="somnambulism"></a>Somnambulism:</b>
Sleepwalking. As with night terrors, this behavior occurs in the first few
hours of sleep. An episode may last up to 30 minutes. During the episode,
the character�s face is blank and staring, and she can be roused only with
difficulty. Once awake, she recalls nothing of the activity.
    </p>
    <h6><a id="somatoform-disorders"></a>
Somatoform Disorders
    </h6>
A somatoform disorder may be diagnosed when a character experiences
physical symptoms that cannot be explained by an actual physical injury or
disease.
    <p><b><a id="somatization-disorder"></a>Somatization Disorder:</b>
The character suffers from a physical ailment or diseaselike effect, with
symptoms ranging from dizziness and impotence to blindness and intense
pain. The <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#heal">Heal</a> skill cannot identify any physical cause for the symptoms,
and magical healing has no effect. The victim does not believe that her
symptoms represent a specific disease. All attack rolls, saves, and checks
take a -2 penalty.
    </p>
    <p><b><a id="conversion-disorder"></a>Conversion Disorder:</b>
The character reports dysfunctions that suggest a physical disorder but,
though they are involuntary, the symptoms actually provide a way for the
victim to avoid something undesirable or a way to garner attention and
caring, a condition called Munchausenism. Symptoms range from painful
headaches to paralysis or blindness. With the condition known as Reverse
Munchausenism, a character projects ill health onto others and may even
arrange injuries or illnesses for them so that she can thereafter take
care of them. All attack rolls, saves, and checks take a -2 penalty.
    </p>
    <p><b><a id="hypochondriasis"></a>Hypochondriasis:</b>
Character believes she suffers from a serious disease. No physical cause
for reported symptoms can be found, but the character continues to believe
that the disease or condition exists, often with serious consequences to
her normal life.
    </p>
    <p><b><a id="body-dysmorphic-disorder"></a>Body Dysmorphic Disorder:</b>
Character suffers from perceived flaws in appearance, usually of the face,
or of the hips or legs. Behavior may alter in unexpected ways to cover up
the flaws or to calm anxieties.
    </p>
    <h6><a id="substance-abuse-disorder"></a>
Substance Abuse Disorder
    </h6>
A character with a substance abuse disorder finds solace in using a drug,
becomes addicted to it, and spends much time maintaining, concealing, and
indulging the habit. Drugs include alcohol, amphetamines, cocaine,
hallucinogens, marijuana, nicotine, opium (especially morphine and
heroin), sedatives, and more fantastic substances present in the campaign
world (see <a style={{
      "color": "#579eb6"
    }} href="#sample-drugs">Sample Drugs</a>, earlier in this section).
    <p>
A character under the sway of such a substance should feel the personal
struggle daily. Will saving throws might be used to resist or succumb
symbolically to cravings, especially just before periods of stress (for
example, just before a confrontation or likely battle with evil cultists).
All attack rolls, saves, and checks take a -2 morale penalty because of
withdrawal symptoms. Sanity losses could occur from binges or bad trips.
Some characters might find that drugs promote communication with alien
entities and deities, and that dreams about them become ever more vivid
and horrifying, Conversely, such substances might function as medications,
deadening a character�s fears and offering temporary defenses against
Sanity loss.
    </p>
    <h6><a id="other-disorders"></a>
Other Disorders
    </h6>
Other disorders exist in common parlance, but most of these are actually
symptoms or specific instances of disorders already mentioned above. These
include quixotism (seeing the supernatural everywhere, even in the most
mundane surroundings), panzaism (seeing the most extraordinary events as
ordinary and rational), and megalomania (delusions of power, wealth, fame,
and ability). Use or ignore these as suits your campaign, or invent new
categories of madness to reflect the chaos that lies just below the
brittle surface of Lovecraft�s world.
    <h5><a id="psychiatric-glossary"></a>
A PSYCHIATRIC GLOSSARY
    </h5>
The following words are defined in terms of a real-world understanding of
insanity; some of them (illusion, for example) have different meanings in
a d20 game context. As with all aspects of the Sanity variant, GMs need to
determine how each race and culture within the campaign world views
insanity and how capable each race and culture is of treating mental
disorders in order to know which of these words might come into play.
    <p><b><a id="glossary-affect"></a>Affect:</b>
The external expression of a patient�s mood (sadness, anger, joy, fear).
May be inconsistent with patient�s mood, depending on the disorder.
    </p>
    <p><b><a id="glossary-anorexia"></a>Anorexia:</b>
Loss or decrease of appetite.
    </p>
    <p><b><a id="glossary-catatonia"></a>Catatonia:</b>
Various strong motor anomalies, for instance catatonic stupor (slowed
activity to the point of immobilization); ceraflexibilitas (the victim can
be molded into strange postures that are maintained), and catatonic
excitement (agitated, purposeless movements).
    </p>
    <p><b><a id="glossary-compulsion"></a>Compulsion:</b>
The need to perform certain actions repetitively, including various
personal rituals, dipsomania, kleptomania, nymphomania, satyriasis,
trichotillomania (pulling out hair), and so on.
    </p>
    <p><b><a id="glossary-delirium"></a>Delirium:</b>
A reversible syndrome of bewilderment, restlessness, confusion, and
disorientation, associated with fear and hallucinations, all caused by
some underlying medical condition.
    </p>
    <p><b><a id="glossary-delusion"></a>Delusion:</b>
A firmly fixed false belief, one not based in reality. It can be bizarre,
as in schizophrenia, or systematized, as in delusional disorders.
    </p>
    <p><b><a id="glossary-dementia"></a>Dementia:</b>
A loss of cognitive function, often first manifesting in memory loss.
    </p>
    <p><b><a id="glossary-depersonalization"></a>Depersonalization:</b>
A subjective feeling of being unreal, or unfamiliar to self.
    </p>
    <p><b><a id="glossary-derealization"></a>Derealization:</b>
A subjective feeling that the environment is strange or unreal; for
instance, feeling the world to be a stage or a two-dimensional painting.
    </p>
    <p><b><a id="glossary-dissosiation"></a>Dissociation:</b>
Confusion over one�s sense of self and identity.
    </p>
    <p><b><a id="glossary-formication"></a>Formication:</b>
The feeling that insects are crawling all over one�s body, a tactile
hallucination caused by cocaine and delirium tremens.
    </p>
    <p><b><a id="glossary-hallucination"></a>Hallucination:</b>
A perception of a sensory stimulus in the absence of sensory stimuli; for
instance, seeing or hearing some one who isn�t there.
    </p>
    <p><b><a id="glossary-illusion"></a>Illusion:</b>
The misperception of a sensory stimulus; for instance, seeing the rustling
branches of a tree as tentacles.
    </p>
    <p><b><a id="glossary-logorrhea"></a>Logorrhea:</b>
Copious, coherent, logical speech.
    </p>
    <p><b><a id="glossary-mania"></a>Mania:</b>
A mood characterized by elation and increased activity.
    </p>
    <p><b><a id="glossary-mood"></a>Mood:</b>
A pervasive feeling that is experienced internally.
    </p>
    <p><b><a id="glossary-neurosis"></a>Neurosis:</b>
Symptoms of depression, anxiety, or the like arising from stress. A
neurosis is less severe than a psychosis. A neurotic character may still
be able to function; a psychotic one generally cannot.
    </p>
    <p><b><a id="glossary-obsession"></a>Obsession:</b>
An idea or thought that constantly intrudes into one�s consciousness.
    </p>
    <p><b><a id="glossary-paranoia"></a>Paranoia:</b>
Persistent, consistent, plausible, and ingenious delusions of persecution
or jealousy. New information always seems to support the increasing threat
of some great conspiracy. Paranoia is more a symptom than a disorder,
because it can appear in schizophrenia, mania, and so on.
    </p>
    <p><b><a id="glossary-psychosis"></a>Psychosis:</b>
Severe mental illness in which the character experiences thoughts and
perceptions that are out of touch with reality. A psychosis is more severe
than a neurosis.
    </p>
    <p><b><a id="glossary-somnambulism"></a>Somnambulism:</b>
Sleepwalking.
    </p>
    <p><b><a id="glossary-somnolence"></a>Somnolence:</b>
Abnormal drowsiness.
    </p>
    <p><b><a id="glossary-synhtesia"></a>Synthesia:</b>
Sensation caused by another sensation; for instance, seeing sound.
    </p>
    <p><b><a id="glossary-tic"></a>Tic:</b>
Involuntary spasmodic motor movement.
    </p>
    <p><b><a id="glossary-trailing-phenomenon"></a>Trailing Phenomenon:</b>
Perceptual abnormality associated with hallucinogens in which moving
objects are seen in a series of discrete discontinuous images.
    </p>
    <p><b><a id="glossary-trace"></a>Trance:</b>
Focused attention and altered consciousness, usually seen in hypnosis,
dissociative disorders, and ecstatic religious experiences.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      